import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Login from "./page/guest/Login";
import ForgetPassword from "./page/guest/forget-password/ForgetPassword";
import SuperAdminDashboard from "./page/superadmin/dashboard/Dashboard"
import Profile from "./page/dashboard/Profile";
import Devices from "./page/devices/Devices";
import PrivateRoute from "./PrivateRoute";
import Page404 from "./page/Page404";
import Notifications from "./page/notifications/Notifications";
import Assets from "./page/assets/Assets";
import Vehicle from "./page/vehicle/Vehicle";
import Sites from "./page/sites/Sites";
import Storeys from "./page/storey/Storeys";
import Spaces from "./page/spaces/Spaces";
import Iots from "./page/iots/Iots";
import { useSelector } from "react-redux";
import Tenants from "./page/superadmin/tenants/Tenants";
import Users from "./page/superadmin/users/Users";
import Companies from "./page/superadmin/companies/Companies";
import Alitiqs from "./page/alitiq/Alitiqs"
import Onboarding from "./page/guest/Onboarding";
import SubTenants from "./page/subTenants/SubTenants";
import PermissionRoute from "./PermissionRoute";
import {
  READ_API_SETTING,
  READ_BUILDING,
  READ_DASHBOARD,
  READ_PROFILE,
  READ_DEVICE,
  READ_FLEET,
  READ_FORECAST,
  READ_NOTIFICATION,
  READ_REPORT,
  READ_SITE,
  READ_SPACE,
  READ_STOREY,
  READ_SUB_TENANT,
  READ_SYSTEM,
  READ_TENANT,
  READ_USER,
  READ_COMPANY,
  READ_ALITIQ,
  READ_MARKET,

} from "./utils/permission";
import Dashboard2 from "./page/dashboard/Dashboard2";
import Reports2 from "./page/report/Reports2";
import Settings from "./page/settings/Settings";
import Prediction from "./page/connect/Prediction";
import Solar2 from "./page/solar/Solar2";
import MarketPrices from "./page/market/MarketPrices";
import Systems from "./page/systems/Systems";
import WidgetPage from "./page/widgets/WidetPage";
import TrackProcess from "./page/superadmin/trackProcess/TrackProcess";
import Integrations from "./page/integrations/Integrations";
import Complete from "./page/integrations/Complete";
import Modbus from "./page/temp/Modbus";

const AllRoutes = () => {
  const { user } = useSelector((state: any) => state.user);
  console.log('Users role', user?.role);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path={`/${user?.role}`} element={<PrivateRoute />}>
          {(user?.role === "tenant" || user?.role === "sub-tenant" || user?.role === "support-tenant") && (
            <>
              <Route
                path="dashboard"
                element={
                  <PermissionRoute pass={READ_DASHBOARD}>
                    <Dashboard2 />
                  </PermissionRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <PermissionRoute pass={READ_PROFILE}>
                    <Profile />
                  </PermissionRoute>
                }
              />
              <Route
                path="systems"
                element={
                  <PermissionRoute pass={READ_SYSTEM}>
                    <Systems />
                  </PermissionRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <PermissionRoute pass={READ_DASHBOARD}>
                    <Notifications />
                  </PermissionRoute>
                }
              />
              <Route
                path="buildings"
                element={
                  <PermissionRoute pass={READ_BUILDING}>
                    <Assets />
                  </PermissionRoute>
                }
              />
              
              <Route
                path="forecast"
                element={
                  <PermissionRoute pass={READ_FORECAST}>
                    <Solar2 />
                  </PermissionRoute>
                }
              />
              <Route
                path="market-price"
                element={
                  <PermissionRoute pass={READ_MARKET}>
                    <MarketPrices/>
                  </PermissionRoute>
                }
              />
              <Route
                path="fleets"
                element={
                  <PermissionRoute pass={READ_FLEET}>
                    <Vehicle />
                  </PermissionRoute>
                }
              />
              <Route
                path="sites"
                element={
                  <PermissionRoute pass={READ_SITE}>
                    <Sites />
                  </PermissionRoute>
                }
              />
              <Route
                path="buildings"
                element={
                  <PermissionRoute pass={READ_BUILDING}>
                    <Assets />
                  </PermissionRoute>
                }
              />
              <Route
                path="storeys"
                element={
                  <PermissionRoute pass={READ_STOREY}>
                    <Storeys />
                  </PermissionRoute>
                }
              />
              <Route
                path="spaces"
                element={
                  <PermissionRoute pass={READ_SPACE}>
                    <Spaces />
                  </PermissionRoute>
                }
              />
              <Route
                path="devices"
                element={
                  <PermissionRoute pass={READ_DEVICE}>
                    <Iots />
                  </PermissionRoute>
                }
              />
              <Route
                path="integrations"
                element={
                  <PermissionRoute pass={READ_API_SETTING}>
                    <Integrations />
                  </PermissionRoute>
                }
              />
              <Route
                path="reports"
                element={
                  <PermissionRoute pass={READ_REPORT}>
                    <Reports2 />
                  </PermissionRoute>
                }
              />
              <Route
                path="sub-tenant"
                element={
                  <PermissionRoute pass={READ_SUB_TENANT}>
                    <SubTenants />
                  </PermissionRoute>
                }
              />
              <Route
                path="alitiq"
                element={
                  <PermissionRoute pass={READ_ALITIQ}>
                    <Alitiqs />
                  </PermissionRoute>
                }
              />
              <Route path="widgets" element={<WidgetPage />} />

              <Route path="complete" element={<Complete/>}/>

              <Route path="simulator" element={<Modbus />} />


              {/* <Route path="trees" element={<TreeExample />} />
              <Route path="trees2" element={<Tree />} />
              <Route path="connects" element={<Connect />} />
              <Route path="connects/huawei" element={<Huawei />} /> */}
              <Route path="predictions" element={<Prediction />} />
              {/* <Route path="connects/huawei/devices" element={<DeviceDetails />} /> */}
            </>
          )}
          {(user?.role === "super-admin" || user?.role === "support-superadmin") && (
            <>
              <Route
                path="dashboard"
                element={
                  <PermissionRoute pass={READ_DASHBOARD}>
                    <SuperAdminDashboard />
                  </PermissionRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <PermissionRoute pass={READ_PROFILE}>
                    <Profile />
                  </PermissionRoute>
                }
              />
              <Route
                path="tenants"
                element={
                  <PermissionRoute pass={READ_TENANT}>
                    <Tenants />
                  </PermissionRoute>
                }
              />
              <Route
                path="users"
                element={
                  <PermissionRoute pass={READ_USER}>
                    <Users />
                  </PermissionRoute>
                }
              />
              <Route
                path="companies"
                element={
                  <PermissionRoute pass={READ_COMPANY}>
                    <Companies />
                  </PermissionRoute>
                }
              />
              {/* <Route
                path="integrations"
                element={
                  <PermissionRoute pass={READ_API_SETTING}>
                    <Settings />
                  </PermissionRoute>
                }
              /> */}
              <Route
                path="track-process"
                element={
                  <PermissionRoute pass={READ_COMPANY}>
                    <TrackProcess />
                  </PermissionRoute>
                }
              />
            </>
          )}
          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
