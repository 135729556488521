import { Button, Drawer, Tabs } from "antd";
import { ArrowBigRightDash } from "lucide-react";
import React, { useEffect, useState } from "react";
import EditStoreyComponent from "./EditStoreyComponent";
import { client } from "../../useRequest";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import EditAttributeComponents from "./EditAttributeComponent";
import EditButton from "../../components/button/EditButton";

type EditStoreyProps = {
    data: any;
    mutate?: any;
};
const EditStorey: React.FC<EditStoreyProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [attributes, setAttributes] = useState({} as any);
    const [loading, setLoading] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editStorey = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            const response = await client2.put(`api/storey/${data?.id}`, {
                storey: values,
            });
            if (response.status === 200) {
                mutate({ data: "data" });
                setOpen(false);
                toastr.success("Storey Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };

    const editAttribute = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            const response = await client2.put(`api/storey/${data?.id}`, {
                storey: values,
            });
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false);
                toastr.success("Asset Attribute Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };

    return (
        <>
            <EditButton onClick={showDrawer} title="Edit System" />
            <Drawer
                size="large"
                title="Edit Storey"
                onClose={onClose}
                open={open}
            >
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "Storey",
                            key: "1",
                            children: (
                                <EditStoreyComponent
                                    loading={loading}
                                    initialValue={{
                                        ...data,
                                        storey_id: !!data.storey_id
                                            ? `${data.storey_id}|${data.storey_string_type}`
                                            : null,
                                    }}
                                    onSubmit={editStorey}
                                />
                            ),
                        },
                    ]}
                />
            </Drawer>
        </>
    );
};

export default EditStorey;
