import { Button, Drawer, Tabs, Spin } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React, { useState } from 'react';
import toastr from "toastr";
import { client2 } from '../../../operationalRequest';
import EditPermissionComponent from './EditPermissionComponent';

type EditUserProps = {
    data: any;
    mutate?: any;
    companyId?: string;
};

const EditUser: React.FC<EditUserProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [userPermissions, setUserPermissions] = useState<string[]>([]);

    const showDrawer = async () => {
        setOpen(true);
        if (data?.id) {
            setFetching(true);
            await fetchPermissions();
            setFetching(false);
        }
    };

    const onClose = () => {
        setOpen(false);
        setUserPermissions([]);
    };

    const editPermission = async (values: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const response = await client2.put(`api/user/permission/${data?.id}`, {
                ...values
            });
            if (response.status === 200) {
                mutate(response.data.data);
                setOpen(false);
                toastr.success("Permissions Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchPermissions = async () => {
        try {
            const [userResponse] = await Promise.all([
                client2.get(`api/user/permission/${data?.id}`),
            ]);
            if (userResponse.data) {
                //console.log("userResponse.data", userResponse.data)
                setUserPermissions(userResponse.data.data || []);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    return (
        <>
            <Button
                onClick={showDrawer}
                size="small"
                className="bg-blue-500 size-8 rounded-full py-3 flex items-center"
            >
                <ArrowBigRightDash />
            </Button>
            <Drawer size="large" title="Edit User" onClose={onClose} open={open}>
                {fetching ? (
                    <Spin size="large" />
                ) : (
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: 'Permissions',
                                key: '1',
                                children: (
                                    <EditPermissionComponent
                                        key={data?.id}
                                        loading={loading}
                                        initialValue={{
                                            permissions: userPermissions,
                                            role: data.role,
                                            id: data?.id,
                                        }}
                                        onSubmit={editPermission}
                                    />
                                ),
                            },
                        ]}
                    />
                )}
            </Drawer>
        </>
    );
};

export default EditUser;
