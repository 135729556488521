import React, { useEffect, useState } from "react";
import EditAssetComponent from "./EditAssetComponent";
import toastr from "toastr";
import { Modal } from "antd";
import { client2 } from "../../operationalRequest";
import AddButton from "../../components/button/AddButton";

type AddAssetProps = {
    mutate?: any;
};
const AddAssets: React.FC<AddAssetProps> = ({ mutate }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const onSubmit = async (values: any, form: any) => {
        console.log(process.env.REACT_APP_OPERATIONAL_URL);
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            const response = await client2.post("api/building", {
                building: values,
            });
            mutate({ data: response.data });
            setOpen(false);
            toastr.success("Building Created Successfully");
            form.resetFields();
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };
    return (
        <>
            <AddButton title="Add Building" setOpen={setOpen} />
            <Modal
                title={"Add Building"}
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={800}
                okButtonProps={{ style: { display: "none" } }}
            >
                <EditAssetComponent loading={loading} onSubmit={onSubmit} />
            </Modal>
        </>
    );
};

export default AddAssets;
