import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import OperationalGrid from "../../components/datatable/OperationalGrid";
import columns from "./SiteColumn";
import MapLeaf from "../../components/MapLeaf";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import AddSite from "./AddSite";
import { CREATE_SITE } from "../../utils/permission";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import LeafMap from "../../components/LeafMap";
const Sites = () => {
    const [split, setSplit] = useState(false);
    const [asset, setAsset] = useState(null as any);
    const [mutate, setMutate] = useState({} as any);
    const [locations, setLocations] = useState([] as any);
    const handleInfo = (values: any) => {
        setLocations(
          values?.data?.sites
            .filter((it: any) => {
            console.log(values)
            //  console.log('attributes', !!it.attributes && !!it.attributes?.latitude, it.attributes)
              return (
                !!it.attributes &&
                it.attributes?.latitude &&
                it.attributes?.longitude
              );
            })
            .map((item: any, i: number) => {
              return {
                lat: item.attributes?.latitude,
                lng: item.attributes?.longitude,
                name: item.name,
              };
            })
        );
      };
    return (
        <Layout title="Sites" breadcrumbs={["sites", "buildings", "storeys", "spaces"]}>
            <MapLeaf locations={locations} />
            <OperationalGrid
                setInfo={handleInfo}
                columns={columns(setMutate, setAsset, setSplit)}
                url={"api/site"}
                context="sites"
                addBody={
                    <PermissionCheck permission={CREATE_SITE}>
                        <AddSite mutate={setMutate} />
                    </PermissionCheck>
                }
                mutate={mutate}
                paginationOption={true}
            />
            <SiteAttribute
                onClick={() => setSplit(false)}
                asset={asset}
                expanded={split}
            />
        </Layout>
    );
};
type SiteAttributeProps = {
    asset: any;
    expanded: boolean;
    onClick: any;
};
const SiteAttribute: React.FC<SiteAttributeProps> = ({
    asset,
    expanded,
    onClick,
}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!!asset?.attributes && expanded) {
            setLoading(false);
        }
    }, [asset, expanded]);

    if (!expanded) {
        return <></>;
    }
    return (
        <Modal
            okButtonProps={{ style: { display: "none" } }}
            width={"auto"}
            title="Site"
            open={expanded}
            onCancel={onClick}
        >
            <Spin className="flex justify-start" spinning={loading}>
                <Card className="absolute z-[10000] m-5 ml-16 p-2 drop-shadow-md shadow-lg max-w-[300px]">
                    {Object.entries(asset?.attributes || {}).map(
                        ([key, value]: any, i) => {
                            return (
                                <p key={i}>
                                    <span>
                                        <strong>{key.toUpperCase()}</strong>:{" "}
                                        {value}{" "}
                                    </span>
                                </p>
                            );
                        },
                    )}
                </Card>
                {!!asset?.attributes && (
                    <LeafMap
                        position={[
                            asset.attributes.latitude,
                            asset.attributes.longitude,
                        ]}
                    />
                )}
            </Spin>
        </Modal>
    );
};
export default Sites;
