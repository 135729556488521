import { useEffect, useState } from "react";
import { client2 } from "../../operationalRequest";
import { Button, Modal } from "antd";
import { Info } from "lucide-react";
import _ from "lodash";

type NotificationDetailsProps = {
    id: string;
    setMutate: any;
};

const NotificationDetails: React.FC<NotificationDetailsProps> = ({
    id,
    setMutate,
}) => {
    const [toggle, setToggle] = useState(false);
    const [data, setData] = useState([] as any);

    const fetchNotificationDetails = async () => {
        const response = await client2.put(`api/notifications/${id}`, {
            notification: { status: "read" },
        });
        if (response.status === 200) {
            setData(response.data);
            setMutate({ data: Math.random() });
        }
    };

    useEffect(() => {
        if (!!toggle) {
            fetchNotificationDetails();
        }
    }, [toggle]);

    return (
        <>
            <Button
                size="small"
                className="bg-blue-400 size-8 rounded-full py-3 flex items-center text-white"
                type="primary"
                onClick={() => setToggle(true)}
            >
                <Info />
            </Button>
            <Modal
                okButtonProps={{ style: { display: "none" } }}
                title="Notification Info"
                open={toggle}
                onCancel={() => setToggle(false)}
            >
                {Object.entries(data.data || {}).map(([key, value]: any, i) => {
                    return (
                        <p key={i}>
                            <span>
                                <strong>
                                    {_.words(key).join(" ").toUpperCase()}
                                </strong>
                                : {value}
                            </span>
                        </p>
                    );
                })}

                {Object.entries(data.meta || {}).map(([key, value]: any, i) => {
                    return (
                        <p key={i}>
                            <span>
                                <b>{_.words(key).join(" ").toUpperCase()}</b>:{" "}
                                {value}
                            </span>
                        </p>
                    );
                })}
            </Modal>
        </>
    );
};

export default NotificationDetails;
