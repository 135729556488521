export const NOTIFICATION_TYPES = [
    { value: "alert", label: "Alert" },
    { value: "general", label: "General" },
    { value: "reminder", label: "Reminder" },
    { value: "update", label: "Update" },
];

export const NOTIFICATION_STATUSES = [
    { value: "unread", label: "Unread" },
    { value: "read", label: "Read" },
];

export const NOTIFICATION_PRIORITIES = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
];
