import React, { useState } from "react";
import { Drawer, Tabs } from "antd";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import EditSpaceComponent from "./EditSpaceComponent";
import EditButton from "../../components/button/EditButton";

type EditSpaceProps = {
    data: any;
    mutate?: any;
};
const EditSpace: React.FC<EditSpaceProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editSpace = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            const response = await client2.put(`api/space/${data?.id}`, {
                space: values,
            });
            if (response.status === 200) {
                mutate({ data: "data" });
                setOpen(false);
                toastr.success("Space Updated Successfully!");
            }
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };

    return (
        <>
            <EditButton onClick={showDrawer} title="Edit Space" />
            <Drawer
                size="large"
                title="Edit Space"
                onClose={onClose}
                open={open}
            >
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "Space",
                            key: "1",
                            children: (
                                <EditSpaceComponent
                                    loading={loading}
                                    initialValue={{
                                        ...data,
                                        space_id: !!data.space_id
                                            ? `${data.space_id}|${data.space_string_type}`
                                            : null,
                                    }}
                                    onSubmit={editSpace}
                                />
                            ),
                        },
                    ]}
                />
            </Drawer>
        </>
    );
};

export default EditSpace;
