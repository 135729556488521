import React from 'react'
import PermissionCheck from '../../components/authorizeComponent/PermissionCheck'
import DeleteButton from '../../components/authorizeComponent/DeleteButton'
import { DESTROY_SYSTEM, UPDATE_SYSTEM } from '../../utils/permission'
import EditIntegration from './EditIntegration'


type ActionProps = {
    setMutate: (mutate: any) => void;
    record: any;
}
const Action: React.FC<ActionProps> = ({
    setMutate,
    record
}) => {
    return (
        <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_SYSTEM}>
                <EditIntegration data={record} mutate={setMutate} />
            </PermissionCheck>
            <DeleteButton
                model="integrations"
                title="integration"
                id={record.id}
                setMutate={setMutate}
                permission={DESTROY_SYSTEM}
            />
        </div>
    )
}

export default Action
