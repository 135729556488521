import { Col, Form, Row, Select } from "antd";
import React, { useState } from 'react';
import LoadingButton from "../../../components/LoadingButton";
import _ from "lodash";
import TextField from "../../../components/formComponents/TextField";

type EditCompanyComponentProps = {
  onSubmit: (values: any, form: any) => void;
  initialValue?: any; // The initial user data
  loading: boolean;
};
const EditCompanyComponent: React.FC<EditCompanyComponentProps> = ({ onSubmit, initialValue, loading }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      initialValues={{
        ...initialValue
      }}
      onFinish={(values: any) => {
        onSubmit(values, form);
      }}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <TextField label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextField label="Phone" name="phone" rules={[{ required: false, message: 'Please input first phone!' }]} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextField label="Address" name="address" rules={[{ required: false, message: 'Please input address!' }]} />
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
          <TextField label="Details" name="details" rules={[{ required: false, message: 'Please input details!' }]} />
        </Col>
      </Row> */}
      <Row>
        <Col span={24}>
          <Form.Item>
            <LoadingButton loading={loading} title={_.isEmpty(initialValue) ? "Save" : "Update"} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditCompanyComponent;
