import { useState, useEffect } from "react";
import { Bell } from "lucide-react";
import { client2 } from "../operationalRequest";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface Notification {
    id: string;
    message: string;
    timestamp: string;
}

const NotificationBell = () => {
    const [count, setCount] = useState(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const { role } = useSelector((state: any) => state.role);

    const [filter, setFilter] = useState<"all" | "unread">("all");

    const fetchNotifications = async (status: "all" | "unread") => {
        try {
            const statusFilter =
                status === "unread" ? "&filter[status]=unread" : "";
            const response = await client2.get(
                `api/notifications?page=1&pageSize=10&sortOrder=desc&sortProperty=created_at${statusFilter}`,
            );
            setNotifications(response.data.data.notifications);
            setCount(response.data.data.unread_count);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    useEffect(() => {
        fetchNotifications(filter);
    }, [filter, count]);

    const handleNotificationClick = async (id: string) => {
        try {
            await client2.put(`api/notifications/${id}`, {
                notification: { status: "read" },
            });

            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    notification.id === id ? { ...notification } : notification,
                ),
            );

            setCount((prevCount) => Math.max(prevCount - 1, 0));
            navigate(`/${role.role[0]}/notifications`);
        } catch (error) {
            console.error("Error updating notification:", error);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const handleFilterChange = (newFilter: "all" | "unread") => {
        setFilter(newFilter);
    };

    return (
        <div className="relative inline-flex items-center cursor-pointer">
            <div
                onClick={toggleDropdown}
                aria-label="Notifications"
                role="button"
            >
                <Bell className="text-gray-700 w-6 h-6" size={24} />
                {count > 0 && (
                    <span className="absolute -top-1 -right-1 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                        {count > 99 ? "99+" : count}
                    </span>
                )}
            </div>

            {isDropdownOpen && (
                <div className="absolute right-0 top-full mt-2 w-80 bg-white border border-gray-200 rounded-lg shadow-lg">
                    <div className="flex justify-between items-center border-b p-4">
                        <div className=" font-bold text-gray-700 ">
                            Notifications
                        </div>
                    </div>
                    <div className="flex p-4 gap-4 text-gray-700">
                        <button
                            className={`${
                                filter === "all"
                                    ? "font-bold text-blue-500"
                                    : "text-gray-700"
                            }`}
                            onClick={() => handleFilterChange("all")}
                        >
                            All
                        </button>
                        <button
                            className={`${
                                filter === "unread"
                                    ? "font-bold text-blue-500"
                                    : "text-gray-700"
                            }`}
                            onClick={() => handleFilterChange("unread")}
                        >
                            Unread
                        </button>
                    </div>
                    <ul className="max-h-60 overflow-y-auto">
                        {notifications.length > 0 ? (
                            notifications.map((notification: any) => (
                                <li
                                    key={notification.id}
                                    onClick={() =>
                                        handleNotificationClick(notification.id)
                                    }
                                    className={`p-3 text-sm cursor-pointer ${
                                        notification.status === "read"
                                            ? "bg-gray-200 text-gray-900"
                                            : "bg-white text-gray-900"
                                    } hover:bg-gray-200`}
                                >
                                    <div className="flex justify-between items-center">
                                        <p>{notification.data.title}</p>
                                        {notification.status === "unread" ? (
                                            <div className="bg-blue-600 h-2 w-2 rounded-full"></div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <span className="text-xs text-gray-500">
                                        {new Date(
                                            notification.created_at,
                                        ).toLocaleString()}
                                    </span>
                                </li>
                            ))
                        ) : (
                            <li className="p-3 text-sm text-center text-gray-500">
                                No notifications
                            </li>
                        )}
                    </ul>

                    <Link to="/tenant/notifications">
                        <div className="p-3 text-center text-sm text-blue-500 hover:underline cursor-pointer">
                            View all notifications
                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default NotificationBell;
