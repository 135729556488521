import { Button, Form, message, Steps, theme } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TextField from '../../components/formComponents/TextField';
import CustomSelectOptions from '../../components/formComponents/CustomSelectOptions';
import LoadingButton from '../../components/LoadingButton';
import { client2 } from '../../operationalRequest';
import ImportDevices from './ImportDevices';

type IntegrateProps = {
    mutate?: any;
};
const Integrate: React.FC<IntegrateProps> = ({mutate}) => {
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [initialValue, setInitialValue] = useState({} as any)
    const [loading, setLoading] = useState(false)
    const [integrationId, setIntegrationId] = useState(null);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const connect = async (values: any) => {
        try {
            const response = await client2.post('api/integration/connect', { ...values, ...initialValue });
            if (![2, 3, 4].includes(initialValue.manufacture_type)) {
                const popupFeatures = 'width=600,height=700,scrollbars=yes,resizable=yes';
                const newWindow = window.open(response.data.data.linkUrl, '_blank', popupFeatures);
                if (newWindow) {
                    const handleMessage = (event: MessageEvent) => {
                        console.log("Received message from popup:", event.data);
                        // Ensure the message is from the expected origin
                        if (event.data?.success && event.data?.data) {
                            setIntegrationId(event.data.data.id);
                            mutate(event.data.data);
                            next();
                            window.removeEventListener("message", handleMessage);
                        }
                    };
    
                    // Listen for messages from the popup
                    window.addEventListener("message", handleMessage);
    
                    // Detect when the popup is closed
                    const checkClosed = setInterval(() => {
                        console.log("Checking if popup window is closed...");
                        if (newWindow.closed) {
                            clearInterval(checkClosed);
                            console.log("Popup window closed!");
                        }
                    }, 500);
                }
            }else{
                console.log(response.data)
                setIntegrationId(response.data.data.id);
                mutate(response.data.data);
                next();
            }
        } catch (e) {
            console.log(e);
        }
    };
    


    const steps = [
        {
            title: 'Integrate',
            content: <FirstContent steps={3} current={current} onSubmit={(values: any) => {
                setInitialValue({ ...values });
                next();
            }} initialValues={initialValue} />,
        },
        {
            title: 'Connect',
            content: <SecondContent onSubmit={connect} initialValue={initialValue} steps={3} current={current} prev={() => prev()} />,
        },
        {
            title: 'Import',
            content: <ImportDevices integrationId={integrationId} loading={loading} setLoading={setLoading} />,
        },
    ];

    const items = steps.map((item) => ({ key: item.title, title: item.title }));
    return (
        <div>
            <Steps size='small' current={current} items={items} />
            <div>{steps[current].content}</div>
            <div style={{ marginTop: 24 }}>

                {/* {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )} */}
            </div>
        </div>
    )
}

export default Integrate

type FirstContentProps = {
    steps: any,
    current: any,
    onSubmit: any,
    initialValues: any,
}
const FirstContent: React.FC<FirstContentProps> = ({ steps, current, onSubmit, initialValues }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    const manufactureOptions = [
        { value: 1, label: "Huawei", devices: ["inverter", "meter", "battery"] },
        { value: 2, label: "GoodWe", devices: ["inverter"] },
        { value: 3, label: "DEDDIE", devices: ["meter"] },
        { value: 4, label: "ReMoni", devices: ["consumption"] },
        { value: 5, label: "Enphase", devices: ["inverter", "meter", "battery"] },
        { value: 6, label: "Fronius", devices: ["inverter"] },
        { value: 7, label: "Growatt", devices: ["inverter"] },
        { value: 8, label: "Hoymiles", devices: ["inverter"] },
        { value: 9, label: "SMA", devices: ["inverter", "meter", "battery"] },
        { value: 10, label: "Sofar", devices: ["inverter"] },
        { value: 11, label: "SolarEdge", devices: ["inverter", "meter", "battery"] },
        { value: 12, label: "Solax", devices: ["inverter"] },
        { value: 13, label: "Solis", devices: ["inverter"] },
        { value: 14, label: "Tesla", devices: ["inverter", "meter", "battery"] },
        { value: 15, label: "Sungrow", devices: ["inverter"] },
    ] as any;

    const deviceTypeOptions = {
        inverter: { value: "inverter", label: "Inverter" },
        meter: { value: "meter", label: "Meter" },
        battery: { value: "battery", label: "Battery" },
        consumption: { value: "consumption", label: "Load" }
    } as any;

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{ ...initialValues }}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
        >
            <br />
            <TextField name={'name'} label='Name' rules={[{ required: true, message: "Please input type!" }]} />

            <Form.Item
                label="Manufacture"
                name="manufacture_type"
                rules={[{ required: true, message: "Please select a manufacturer!" }]}
            >
                <CustomSelectOptions
                    options={manufactureOptions}
                    onChange={(val: any) => {
                        form.setFieldValue("manufacture_type", val);
                        form.setFieldValue("device_type", undefined);
                    }}
                />
            </Form.Item>

            <Form.Item
                noStyle
                dependencies={["manufacture_type"]}
            >
                {({ getFieldValue }) => {
                    console.log(getFieldValue('manufacture_type'))
                    const type = manufactureOptions.find((m: any) => m.value === getFieldValue('manufacture_type'))?.devices?.map((d: any) => deviceTypeOptions[d]) || [];
                    console.log(type)
                    return (
                        <Form.Item
                            label="Device Type"
                            name="device_type"
                            rules={[{ required: true, message: "Please select a device type!" }]}
                            dependencies={["manufacture_type"]}
                        >

                            <CustomSelectOptions
                                options={type}
                                onChange={(val: any) => form.setFieldValue("device_type", val)}
                            />
                        </Form.Item>

                    )
                }}

            </Form.Item>

            {current < steps - 1 && (
                <Form.Item>
                    <LoadingButton
                        loading={loading}
                        title={"Next"}
                    />
                </Form.Item>
            )}
        </Form>
    )
}

type SecondContentProps = {
    current: any,
    steps: any,
    initialValue: any,
    prev: any,
    onSubmit: any
}
const SecondContent: React.FC<SecondContentProps> = ({ initialValue, current, steps, prev, onSubmit }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [type, setType] = useState("");
    const { role } = useSelector((state: any) => state.role);

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{}}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
        >
            <br />
            {!!initialValue && initialValue.manufacture_type == 3 && (
                <>
                    <TextField name={['config', "token"]} type="password" label="Key" rules={[{ required: true, message: "Please input key!" }]} />
                    <TextField name={['config', "taxNumber"]} type="password" label="Tax Number" rules={[{ required: true, message: "Please input key!" }]} />
                </>

            )}
            {!!initialValue && [4, 2].includes(initialValue.manufacture_type) && (
                <>
                    <TextField name={['config', "username"]} label="User Name" rules={[{ required: true, message: "Please input key!" }]} />
                    <TextField name={['config', "password"]} type="password" label="Password" rules={[{ required: true, message: "Please input key!" }]} />
                </>

            )}

            <div className='flex justify-start space-x-3'>
                <Form.Item>
                    <LoadingButton
                        loading={loading}
                        title={"Connect"}
                    />
                </Form.Item>
                <Form.Item>
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
}