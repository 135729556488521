import React, { useState } from 'react'
import Layout from '../../components/layouts/Layout';
import OperationalGrid from '../../components/datatable/OperationalGrid';
import columns from './IntegrationColumn';
import PermissionCheck from '../../components/authorizeComponent/PermissionCheck';
import { CREATE_API_SETTING } from '../../utils/permission';
import AddIntegration from './AddIntegration';

const Integrations = () => {
  
  const [mutate, setMutate] = useState({} as any);

  return (
    <Layout title="Integrations">
      <OperationalGrid
        columns={columns(setMutate)}
        url={'api/integrations'}
        context="integrations"
        addBody={
          <PermissionCheck permission={CREATE_API_SETTING}>
              <AddIntegration mutate={setMutate}/>
          </PermissionCheck>
        }
        mutate={mutate}
        paginationOption={true}
      />
    </Layout>
  )
}

export default Integrations