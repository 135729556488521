import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import AddAssets from "./AddAsset";
import LeafMap from "../../components/LeafMap";
import { CREATE_BUILDING } from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import OperationalGrid from "../../components/datatable/OperationalGrid";
import columns from "./AssetColumn";
const Assets = () => {
    const [split, setSplit] = useState(false);
    const [asset, setAsset] = useState(null as any);
    const [mutate, setMutate] = useState({} as any);
    return (
        <Layout title="Buildings" breadcrumbs={["sites", "buildings", "storeys", "spaces"]}>
            <OperationalGrid
                columns={columns(setMutate)}
                url={"api/building"}
                context="buildings"
                addBody={
                    <PermissionCheck permission={CREATE_BUILDING}>
                        <AddAssets mutate={setMutate} />
                    </PermissionCheck>
                }
                mutate={mutate}
                paginationOption={true}
            />
            <AssetAttribute
                onClick={() => setSplit(false)}
                asset={asset}
                expanded={split}
            />
        </Layout>
    );
};
type AssetAttributeProps = {
    asset: any;
    expanded: boolean;
    onClick: any;
};
const AssetAttribute: React.FC<AssetAttributeProps> = ({
    asset,
    expanded,
    onClick,
}) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!!asset?.attributes && expanded) {
            setLoading(false);
        }
    }, [asset, expanded]);

    if (!expanded) {
        return <></>;
    }
    return (
        <Modal
            okButtonProps={{ style: { display: "none" } }}
            width={"auto"}
            title="Asset"
            open={expanded}
            onCancel={onClick}
        >
            <Spin className="flex justify-start" spinning={loading}>
                <Card className="absolute z-[10000] m-5 ml-16 p-2 drop-shadow-md shadow-lg max-w-[300px]">
                    {Object.entries(asset?.attributes || {}).map(
                        ([key, value]: any, i) => {
                            return (
                                <p key={i}>
                                    <span>
                                        <strong>{key.toUpperCase()}</strong>:{" "}
                                        {value}{" "}
                                    </span>
                                </p>
                            );
                        },
                    )}
                </Card>
                {!!asset?.attributes && (
                    <LeafMap
                        position={[
                            asset.attributes.latitude,
                            asset.attributes.longitude,
                        ]}
                    />
                )}
            </Spin>
        </Modal>
    );
};
export default Assets;
