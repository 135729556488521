import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  CrossIcon,
  Delete,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import toastr from "toastr";
import columns from './StoreyColumn'
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import { client2 } from "../../operationalRequest";
import EditStorey from "./EditStorey";
import AddStorey from "./AddStorey";
import OperationalGrid from '../../components/datatable/OperationalGrid'
import {
  CREATE_STOREY,
  UPDATE_STOREY,
  DESTROY_STOREY,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Storeys = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const [locations, setLocations] = useState([] as any);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/storey/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Storey Deleted Successfully");
    // }
  };

  const handleInfo = (values: any) => {
    setLocations(
      values?.data?.data
        .filter((it: any) => {
          // console.log('attributes', !!it.attributes && !!it.attributes?.latitude, it.attributes)
          return (
            !!it.attributes &&
            it.attributes?.latitude &&
            it.attributes?.longitude
          );
        })
        .map((item: any, i: number) => {
          return {
            lat: item.attributes?.latitude,
            lng: item.attributes?.longitude,
            name: item.name,
          };
        })
    );
  };

  return (
    <Layout title="Storeys" breadcrumbs={["sites", "buildings", "storeys", "spaces"]}>
      <OperationalGrid
        columns={columns(setMutate)}
        url={'api/storey'}
        context="storeys"
        addBody={
          <PermissionCheck permission={CREATE_STOREY}>
              <AddStorey mutate={setMutate} />
          </PermissionCheck>
        }
        mutate={mutate}
        paginationOption={true}
      />
    </Layout>
  )
};

export default Storeys;
