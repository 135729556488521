import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { client2 } from "../../operationalRequest";
import { tooltip } from "leaflet";

interface NormalizedPerformanceChartProps {
    filter: GraphFilterProps;
    showToolbar?: boolean;
}

interface GraphFilterProps {
    dateType: string;
    date: string;
    siteId?: number;
}

const NormalizedPerformanceChart: React.FC<NormalizedPerformanceChartProps> = (
    props,
) => {
    const [data, setData] = useState<any[]>([]);
    const [options, setOptions] = useState({
        chart: {
            borderRadius: 100,
            responsive: true,
        },
        noData: {
            text: "No data available",
            align: "center",
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: "14px",
            },
        },
        legend: {
            position: "top",
        },
        xaxis: {
            type: "category",
            title: {
                text: props.filter.dateType === "day" ? "Hour of Day" : "Date",
            },
        },
        yaxis: {
            title: {
                text: "Normalized Performance",
            },
            labels: {
                formatter: (value: any) => value.toFixed(2),
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [2, 2, 2, 2],
        },
        tooltip: {
            intersect: false
        }
    });

    const fetchData = async () => {
        const filter = props.filter;
        const params = new URLSearchParams({
            dateType: filter.dateType,
            date: filter.date,
            ...(filter.siteId && { siteId: filter.siteId.toString() }),
        });

        const NormalizedPerformanceChartDataURL = `/api/dashboard/normalized-performance?${params.toString()}`;

        try {
            const response = await client2.get(
                NormalizedPerformanceChartDataURL,
            );

            const formattedData = response.data.data.map((series: any) => ({
                ...series,
                data: series.data.map((point: any) => ({
                    x: point.x,
                    y: point.y,
                })),
            }));

            setData(formattedData);

            const categories =
                formattedData.length > 0
                    ? formattedData[0].data.map((point: any) => point.x)
                    : [];

            setOptions((prevOptions) => ({
                ...prevOptions,
                chart: {
                    ...prevOptions.chart,
                    toolbar: {
                        show: props.showToolbar ?? false,
                    },
                },
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: categories,
                    title: {
                        text:
                            props.filter.dateType === "day"
                                ? "Hour of Day"
                                : "Date",
                    },
                },
            }));
        } catch (error) {
            console.error("Error fetching chart data:", error);
            setData([]);
        }
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                toolbar: {
                    show: props.showToolbar ?? false,
                },
            },
            yaxis: {
                ...prevOptions.yaxis,
                title: {
                    text: "Normalized Performance",
                },
            },
            xaxis: {
                ...prevOptions.xaxis,
                title: {
                    text:
                        props.filter.dateType === "day"
                            ? "Hour of Day"
                            : "Date",
                },
            },
        }));

        fetchData();
    }, [props.filter]);

    return (
        <Chart
            key="line"
            type="line"
            options={options as any}
            series={data}
            width="100%"
            height={350}
        />
    );
};

export default NormalizedPerformanceChart;
