import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { useEffect, useState } from "react";

dayjs.extend(isoWeek);

interface CustomDatePickerProps {
    onDateChange: (dataType: string, date: string | string[]) => void;
    dateFilter: any;
}

const CustomDatePicker = ({
    onDateChange,
    dateFilter,
}: CustomDatePickerProps) => {
    const [dateTypes, setDateTypes] = useState(dateFilter as any);
    const [dateType, setDateType] = useState(dateTypes?.[0] ?? "");
    const [date, setDate] = useState<dayjs.Dayjs | string | string[]>(
        dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    );
    function getDateRange(type: string): string | string[] {
        switch (type) {
            case "day":
                return dayjs().subtract(1, "day").format("YYYY-MM-DD");
            case "week":
                const year = dayjs().year();
                const weekNumber = dayjs().isoWeek();
                return `${year}-${weekNumber}th`;
            case "month":
                return dayjs().format("YYYY-MM");
            case "year":
                return dayjs().format("YYYY");
            default:
                return "";
        }
    }

    const onChange: DatePickerProps["onChange"] = (date, dateString) => {
        setDate(dateString);
        onDateChange(dateType, dateString);
    };

    useEffect(() => {
        if (dateFilter) {
            const newDateType = dateFilter?.[0] ?? "";
            setDateTypes(dateFilter);
            setDateType(newDateType);
            setDate(getDateRange(newDateType));
        }
    }, [dateFilter]);

    useEffect(() => {
        setDate(getDateRange(dateType));
        onDateChange(dateType, getDateRange(dateType));
    }, [dateType]);

    return (
        <div className="flex justify-start space-x-3">
            <div>
                {dateType === "day" && (
                    <DatePicker
                        defaultValue={dayjs().subtract(1, "day")}
                        onChange={onChange}
                    />
                )}
                {dateType === "week" && (
                    <DatePicker
                        defaultValue={dayjs()}
                        onChange={onChange}
                        picker="week"
                    />
                )}
                {dateType === "month" && (
                    <DatePicker
                        defaultValue={dayjs()}
                        onChange={onChange}
                        picker="month"
                    />
                )}
                {dateType === "year" && (
                    <DatePicker
                        defaultValue={dayjs()}
                        onChange={onChange}
                        picker="year"
                    />
                )}
            </div>
            <div className="block">
                <div className="space-x-3 flex justify-start mt-1">
                    {dateTypes?.map((type: string) => (
                        <div
                            key={type}
                            onClick={() => {
                                setDateType(type);
                                onDateChange(type, "");
                            }}
                            className="cursor-pointer"
                        >
                            <span
                                className={`px-2 py-[2px] rounded-lg text-white text-xs 
                            ${dateType === type ? "bg-gray-500" : "bg-blue-600"} 
                            hover:bg-gray-400`}
                            >
                                {type.charAt(0).toUpperCase() + type.slice(1)}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomDatePicker;
