import { useRef, useState } from "react";
import { withSize } from "react-sizeme";
import { Button, Switch } from "antd";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { FileDown } from "lucide-react";

import Layout from "../../components/layouts/Layout";
import { WIDGET_TYPES } from "../../utils/widget";
import CustomDrawer from "../../components/CustomDrawer";
import GridLayout from "../../components/widgets/GridLayout";
import PreviewModal from "./PreviewModal";
import { useReactToPrint } from "react-to-print";

const WidgetPage = () => {
    const [open, setOpen] = useState(false);
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const gridRef = useRef<HTMLDivElement>(null);

    const showDrawer = () => setOpen(!open);
    const openPreview = () => setPreviewOpen(true);
    const closePreview = () => setPreviewOpen(false);
    const [forceRender, setForceRender] = useState(false);

    const handlePrint = useReactToPrint({
        contentRef: gridRef,
        onBeforePrint: async () => {
            setForceRender((prev) => !prev); // Force re-render
            await new Promise((resolve) => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"));
                    resolve(null); // Resolve the promise after dispatching the event
                }, 500);
            });
        },
    });

    return (
        <Layout title="Widgets">
            <div
                className={`flex-grow transition-all duration-300  ${
                    open ? "mr-[150px]" : ""
                }`}
            >
                <div
                    className={`flex ${open ? "justify-end" : "justify-between"} items-center p-2`}
                >
                    <Button
                        onClick={() => openPreview()}
                        className={`flex ${open ? "hidden" : ""} items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600`}
                    >
                        <FileDown className="cursor-pointer" size={20} />
                        <span>Download</span>
                    </Button>

                    <div className="flex items-center gap-2 text-gray-700">
                        <span>Customize</span>
                        <Switch size="small" onClick={showDrawer} />
                    </div>
                </div>

                <div ref={gridRef} className="mt-4">
                    <GridLayout open={open} />
                </div>
            </div>
            <CustomDrawer open={open}>
                <div className="mt-4">
                    <h3>Select and Drag a Widget:</h3>
                    <div className="flex flex-wrap gap-4">
                        {WIDGET_TYPES.map((widget) => (
                            <div
                                key={widget.value}
                                className={widget.value}
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) =>
                                    e.dataTransfer.setData(
                                        "widget-type",
                                        widget.value,
                                    )
                                }
                            >
                                <img
                                    className="rounded-l"
                                    src={widget.img}
                                    width={100}
                                    height={100}
                                    alt={widget.label}
                                />
                                <span>{widget.label}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </CustomDrawer>
            <PreviewModal
                open={isPreviewOpen}
                onClose={closePreview}
                gridRef={gridRef}
            />
        </Layout>
    );
};

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
    WidgetPage,
);
