import React, { useEffect } from 'react'
import { client2 } from '../../operationalRequest';
import { useLocation } from 'react-router-dom';

const Complete = () => {
    const location = useLocation();
    const completeIntegration = async () => {
        try {
            const searchParams = new URLSearchParams(location.search);

            const name = searchParams.get("name"); // "Huawei"
            const manufacture_type = searchParams.get("manufacture_type"); // "7"
            const device_type = searchParams.get("device_type"); // "inverter"
            const response = await client2.post('api/integrations', {name: name, manufacture_type: manufacture_type, device_type: device_type})
            if (window.opener) {
                window.opener.postMessage(
                  { success: true, data: response.data.data },
                  "*"
                );
              }
            window.close();
        } catch (e: any) {
            console.log(e);
        }
    }
    useEffect(() => {
        completeIntegration();
    },[])
  return (
    <div>Complete</div>
  )
}

export default Complete