import React, { useState } from "react";
import Layout from "../../components/layouts/Layout";
import OperationalGrid from "../../components/datatable/OperationalGrid";
import columns from "./SpaceColumn";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import AddSpace from "./AddSpace";
import { CREATE_SPACE } from "../../utils/permission";

const Spaces = () => {
    const [mutate, setMutate] = useState({} as any);

    return (
        <Layout title="Spaces" breadcrumbs={["sites", "buildings", "storeys", "spaces"]}>
            <OperationalGrid
                columns={columns(setMutate)}
                url={"api/space"}
                context="spaces"
                addBody={
                    <PermissionCheck permission={CREATE_SPACE}>
                        <AddSpace mutate={setMutate} />
                    </PermissionCheck>
                }
                mutate={mutate}
                paginationOption={true}
            />
        </Layout>
    );
};

export default Spaces;