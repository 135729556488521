import { Button, Drawer, Tabs } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { client } from '../../useRequest';
import toastr from "toastr";
import { client2 } from '../../operationalRequest';
import EditVehicleComponent from './EditVehicleComponent';
import EditAttributeComponents from './EditAttributeComponent';
import EditButton from "../../components/button/EditButton";
type EditVehicleProps = {
    data: any
    mutate?: any
}
const EditVehicle: React.FC<EditVehicleProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [attributes, setAttributes] = useState({} as any);
    const [loading, setLoading] = useState(false)

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const EditVehicle = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/vehicle/${data?.id}`, {
                vehicle: values
            })
            if (response.status === 200) {
                mutate({ data: 'data' });
                setOpen(false)
                toastr.success("Vehicle Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }

    const editAttribute = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/vehicle/${data?.id}`, {
                vehicle: {
                    name: values.name || data.name,
                    vin: values.vin || data.vin,
                    label: values.label || data.label,
                    description: values.description || data.description,
                    assign_to: values.assign_to ?? data.assign_to,
                    attributes: {
                        ...data.attributes,
                        ...values.attributes,
                    },
                },
            })
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false)
                toastr.success("Vehicle Attributes Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }

    return (
        <>
            <EditButton onClick={showDrawer} title="Edit Vehicle"/>
            <Drawer size='large' title="Edit Vehicle" onClose={onClose} open={open}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'Vehicle',
                            key: '1',
                            children: <EditVehicleComponent loading={loading} initialValue={{ ...data }} onSubmit={EditVehicle} />,
                        },
                        {
                            label: 'Attributes',
                            key: '2',
                            children: <EditAttributeComponents loading={loading} initialValue={{ attributes: data.attributes }} onSubmit={editAttribute} />,
                        },

                    ]}
                />
            </Drawer>
        </>
    );
}

export default EditVehicle
