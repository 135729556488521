import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import { Col, Divider, Row } from 'antd'
import Chart from 'react-apexcharts';
import Notifications from '../../components/Notifications';
import { client2 } from '../../operationalRequest';
import moment from 'moment';
import WeekReport from './WeekReport';
import BatteryReport from './BatteryReport';
import PvReport from './PvReport';
import LoadReport from './LoadReport';
import CarbonReport from './CarbonReport';
import PieChart from './PieChart';
import Input from 'antd/es/input/Input';
import { useSelector } from 'react-redux';
import GraphWidgetWrapper from '../../components/widgets/GraphWidgetWrapper';

const Dashboard2 = () => {
    const [report, setReport] = useState({} as any);
    const {configuration } = useSelector((state: any) => state.user);


    const fetchData = async () => {
        try {
            const response = await client2.get('api/dashboard/report2');
            // const response2 = await client2.get('api/configurations/key/pv-cost-rate');
            setReport(response?.data?.data);
            // setCent(response2?.data?.data?.value);
            //console.log("cent:", cent);
        } catch (e) {
            console.error('Error fetching data:', e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Layout title="Dashboard">
            <Row className='flex justify-between border shadow-sm rounded mt-2 py-4 px-4' gutter={[16, 16]}>
                <Col md={4}>
                    <span>Yesterday's Consumption</span>
                    <div className='flex space-x-1 mt-2'>
                        <h5 className="font-semibold text-lg">{report?.yesterdayConsumption?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} kW</h5>
                        <small className={`${report?.yesterdayConsumptionIncrement < 0 ? 'bg-red-200' : 'bg-green-200'} px-1 my-auto`}>{report?.yesterdayConsumptionIncrement?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0}%</small>
                    </div>
                    <small>Compare to day before yesterday's consumptions</small>
                </Col>
                <Divider className='h-20 my-auto' type="vertical" />
                <Col md={4}>
                    <span>Yesterday's Production</span>
                    <div className='flex space-x-1 mt-2'>
                        <h5 className="font-semibold text-lg">{report?.yesterdayProduction?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} kW</h5>
                        <small className={`${report?.yesterdayProductionIncrement < 0 ? 'bg-red-200' : 'bg-green-200'} px-1 my-auto`}>{report?.yesterdayProductionIncrement?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0}%</small>
                    </div>
                    <small>{'00:00'} - 23:59 </small>
                </Col>
                <Divider className='h-20 my-auto' type="vertical" />
                <Col md={4}>
                    <span>Total Cost This Month</span>
                    <div className='flex flex-col space-x-1 mt-2'>
                        {/* <h5 className="font-semibold text-lg">{(parseFloat(cent))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} €</h5> */}
                        <h5 className="font-semibold text-lg">{(parseFloat(configuration?.find((item: any) => item.key === 'pv-cost-rate')?.value ?? 1) * parseFloat(report?.gridNegative))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} €</h5>
                    </div>
                </Col>
                <Divider className='h-20 my-auto' type="vertical" />
                <Col md={4}>
                    <span>Total Saving This Month</span>
                    <div className='flex space-x-1 mt-2'>
                    <h5 className="font-semibold text-lg">{(parseFloat(configuration?.find((item: any) => item.key === 'pv-cost-rate')?.value ?? 1) * parseFloat(report?.pvProductionMonth))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} €</h5>
                    </div>
                    {/* <small>Compare to last month</small> */}
                </Col>
            </Row>
            <Row className='mt-5 flex justify-between'>
                {/* <Col className='w-auto'> */}
                <WeekReport report={report} />
                {/* </Col> */}
                {/* <Col md={12}>
                    <h1 className='text-lg font-semibold ml-10 mb-2' > Based On Yesterday's Report</h1>
                    <PieChart data={[
                        parseFloat(report?.yesterdayConsumption?.toFixed(2)) ?? 0,
                        parseFloat(report?.yesterdayProduction?.toFixed(2)) ?? 0
                    ]}/>
                </Col> */}
            </Row>
            {/* <Row >
                <Col className='w-full'>
                   <BatteryReport report={report} />
                </Col>

            </Row> */}
            <Row >
                <Col className='w-full'>
                    <PvReport />
                </Col>

            </Row>
            <Row >
                <Col className='w-full'>
                    <LoadReport />
                </Col>

            </Row>
            <Row >
                <Col className='w-full'>
                    <CarbonReport />
                </Col>

            </Row>
            <Row>
                <Col className="w-full">
                    <GraphWidgetWrapper
                        dateType={"day"}
                        date={moment().format("YYYY-MM-DD")}
                        dateFilter={["day", "week", "month"]}
                        filter={{
                            chartType: "line",
                            yAxisTitle: "Normalized Energy Output",
                            kpi: "NEO",
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="w-full">
                    <GraphWidgetWrapper
                        dateType={"day"}
                        date={moment().format("YYYY-MM-DD")}
                        dateFilter={["day", "week", "month"]}
                        filter={{
                            chartType: "line",
                            yAxisTitle: "Normalized Performance",
                            kpi: "NP",
                        }}
                    />
                </Col>
            </Row>
        </Layout>
    )
}

export default Dashboard2