import React, { useEffect, useState } from "react";
import { Checkbox, Form, Input, Select } from "antd";
import _ from "lodash";

import CustomSelectOptions from "../../formComponents/CustomSelectOptions";
import { CHART_TYPES, KPI_OPTIONS } from "../../../constants/widgetsTypes";
import LoadingButton from "../../LoadingButton";
import SelectTable from "../../formComponents/SelectTable";

type EditGraphWidgetComponentProps = {
    onSubmit: any;
    initialValue?: any;
    loading: boolean;
};

const EditGraphWidgetComponent: React.FC<EditGraphWidgetComponentProps> = ({
    onSubmit,
    loading,
    initialValue,
}) => {
    const [form] = Form.useForm();
    const [selectedSystem, setSelectedSystem] = useState<string | null>(null);
    const [selectedSite, setSelectedSite] = useState<string | null>(null);
    const [devicesFilter, setDevicesFilter] = useState<any>();
    const [deviceFilterChecked, setDeviceFilterChecked] = useState(false);

    useEffect(() => {
        if (initialValue?.systemId) {
            setSelectedSystem(initialValue.systemId);
        } else if (initialValue?.siteId) {
            setSelectedSite(initialValue.siteId);
        }
    }, [initialValue]);

    const handleSystemChange = (value: any) => {
        form.setFieldsValue({ systemId: value, siteId: null, deviceId: null });
        setSelectedSystem(value);
        setSelectedSite(null);
    };

    const handleSiteChange = (value: any) => {
        form.setFieldsValue({ siteId: value, systemId: null, deviceId: null });
        setSelectedSystem(null);
        setSelectedSite(value);
    };

    const handleDeviceChange = (value: any) => {
        form.setFieldsValue({ deviceId: value });
    };

    const handleDeviceFilterChange = (e: any) => {
        setDeviceFilterChecked(e.target.checked);
    };

    useEffect(() => {
        if (selectedSystem) {
            setDevicesFilter([
                {
                    table: "system_id",
                    value: selectedSystem,
                },
            ]);
        } else if (selectedSite) {
            setDevicesFilter([
                {
                    table: "location_id",
                    value: selectedSite,
                },
            ]);
        }
    }, [selectedSystem, selectedSite]);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={(values: any) => onSubmit(values, form)}
            initialValues={initialValue}
        >
            <Form.Item
                label="Chart Type"
                name="chartType"
                rules={[
                    {
                        required: true,
                        message: "Please select the Chart type!",
                    },
                ]}
            >
                <CustomSelectOptions
                    options={CHART_TYPES}
                    onChange={(val: any) =>
                        form.setFieldValue("chartType", val)
                    }
                />
            </Form.Item>

            <Form.Item
                label="Title (Y-axis)"
                name="yAxisTitle"
                rules={[
                    {
                        required: true,
                        message: "Please enter title!",
                    },
                ]}
            >
                <Input placeholder="Enter Y Axix Title" />
            </Form.Item>
            <div className="flex flex-row gap-4 mb-4">
                <Form.Item label="System" name="systemId" className="w-full">
                    <SelectTable
                        table="systems"
                        placeholder="Select System"
                        onChange={handleSystemChange}
                        value={form.getFieldValue("system_id")}
                    />
                </Form.Item>
                <Form.Item label="Site" name="siteId" className="w-full">
                    <SelectTable
                        table="sites"
                        placeholder="Select Site"
                        onChange={handleSiteChange}
                        value={form.getFieldValue("site_id")}
                    />
                </Form.Item>
            </div>
            <Form.Item label="KPI" name="kpi">
                <Select
                    mode="multiple"
                    className="w-full"
                    showSearch
                    placeholder="Select Kpis"
                    options={KPI_OPTIONS.filter(
                        (option: any) =>
                            !["cost", "saving"].includes(option.value),
                    )}
                    onChange={(val: any) => form.setFieldValue("kpi", val)}
                    filterOption={(input: any, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={form.getFieldValue("kpi")}
                />
            </Form.Item>
            <Form.Item
                name="dateFilter"
                label="Date Filter"
                rules={[
                    {
                        required: true,
                        message: "Please select the Date type!",
                    },
                ]}
            >
                <CustomSelectOptions
                    options={[
                        { label: "Day", value: "day" },
                        { label: "Week", value: "week" },
                        { label: "Month", value: "month" },
                        { label: "Year", value: "year" },
                        // { label: 'Total', value: 'total' },
                    ]}
                    multiple={true}
                />
            </Form.Item>
            {(selectedSite || selectedSystem) && (
                <Form.Item label="Device" name="deviceId" className="w-full">
                    <SelectTable
                        table="system_devices"
                        placeholder="Select Device"
                        onChange={(val: any, options: any) =>
                            handleDeviceChange(val)
                        }
                        value={form.getFieldValue("device_id")}
                        filter={devicesFilter}
                        key={
                            devicesFilter
                                ? devicesFilter[0]?.value
                                : "no-filter"
                        } // Force re-render by changing the key
                    />
                </Form.Item>
            )}
            <Form.Item name="showToolbar" valuePropName="checked">
                <Checkbox>Show Toolbar</Checkbox>
            </Form.Item>
            <Form.Item>
                <LoadingButton
                    loading={loading}
                    title={_.isEmpty(initialValue) ? "Save" : "Update"}
                />
            </Form.Item>
        </Form>
    );
};

export default EditGraphWidgetComponent;
