import { useState } from "react";
import OperationalGrid from "../../../components/datatable/OperationalGrid";
import columns from "./UserColumn";
import Layout from "../../../components/layouts/Layout";
import AddUser from "./AddUser";
import { CREATE_USER } from "../../../utils/permission";
import PermissionCheck from "../../../components/authorizeComponent/PermissionCheck";
const Users = () => {
    const [mutate, setMutate] = useState({} as any);
    return (
        <Layout title="Users">
            <OperationalGrid
                columns={columns(setMutate)}
                url={"api/users"}
                context="users"
                addBody={
                    <PermissionCheck permission={CREATE_USER}>
                        <AddUser mutate={setMutate} />
                    </PermissionCheck>
                }
                mutate={mutate}
                paginationOption={true}
            />
        </Layout>
    );
};

export default Users;
