import { useState } from "react";
import Layout from "../../components/layouts/Layout";
import OperationalGrid from "../../components/datatable/OperationalGrid";
import columns from "./NotificationColumn";

const Notifications = () => {
    const [mutate, setMutate] = useState({} as any);

    return (
        <Layout title="Notifications">
            <OperationalGrid
                columns={columns(setMutate)}
                url={"api/notifications"}
                context="notifications"
                mutate={mutate}
                paginationOption={true}
            />
        </Layout>
    );
};

export default Notifications;
