import React, { useEffect } from 'react'
import { client2 } from '../../operationalRequest';
import toastr from "toastr";
import SelectTable from '../../components/formComponents/SelectTable';
import { Button, Checkbox, Spin, Table } from 'antd';

type ImportDevicesProps = {
  loading: boolean;
  integrationId?: any;
  setLoading?: any;
}
const ImportDevices: React.FC<ImportDevicesProps> = ({ integrationId, loading, setLoading }) => {
  const [devices, setDevices] = React.useState([] as any);
  const [site, setSite] = React.useState({} as any);
  const [system, setSystem] = React.useState({} as any);
  const fetchDevices = async () => {
    try {
      setLoading(true);
      const response = await client2.get(`api/integration/devices/${integrationId}`);
      setDevices(response.data.data);
      setLoading(false);
    } catch (e: any) {
      console.log(e)
    }
  }

  const onImport = async (record: any) => {
    try {
      const response = await client2.post(`api/integration/import`, { ...record, site: site[`${record.id}`], system: system[`${record.id}`] });
    } catch (e: any) {
      console.log(e)
    }
  }
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: false,
    },
    {
      title: "Import",
      key: "import",
      render: (row: any, record: any) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => handleCheckboxChange(e, record.id)}
        />
      ),
    },
    {
      title: "Site",
      key: "site",
      render: (row: any, record: any) => (
        <SelectTable
          disabled={!record?.checked}
          table="sites"
          placeholder="Select Site"
          value={record?.site}
          onChange={(e: any) => handleInputChange(e, record.id, "site")}
        />
      ),
    },
    {
      title: "System",
      key: "system",
      render: (row: any, record: any) => (
        <SelectTable
          disabled={!record?.checked}
          table="systems"
          placeholder="Select System"
          value={record?.system}
          onChange={(e: any) => handleInputChange(e, record.id, "system")}
        />
      ),
    },
  ];

  const handleCheckboxChange = (e: any, key: any) => {
    const newData = devices.map((item: any) => {
      if (item.id === key) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setDevices(newData);
  };

  const handleInputChange = (e: any, key: any, type: string) => {
    const newData = devices.map((item: any) => {
      if (item.id === key) {
        return { ...item, [`${type}`]: e };
      }
      return item;
    });
    setDevices(newData);
  };
  const importUnit = async () => {
    setLoading(true);
    const record = devices.filter((item: any) => item.checked);
    try {
      const response = await client2.post(`api/integration/import/${integrationId}`, {
        ...record,
      });
      console.log(response)
      if (response.status == 200) {
        toastr.success("Devices Imported Successfully");
        // setConnected(false);
      } else {
        toastr.error("Bad Request");
      }
    } catch (e: any) { }
    setLoading(false);
  };

  useEffect(() => {
    fetchDevices();
  }, [])
  return (
    <div>
      <Table
        loading={{
          spinning: loading,
          indicator: <Spin size="large" style={{ color: "green" }} />,
        }}
        rowKey={(record: any) => record.id}
        dataSource={devices} columns={columns} />
      <Button onClick={importUnit}>Import</Button>
    </div>
  )
}

export default ImportDevices