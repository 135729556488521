import React from "react";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import DeleteButton from "../../components/authorizeComponent/DeleteButton";
import { DESTROY_FLEET, UPDATE_FLEET } from "../../utils/permission";
import EditVehicle from "./EditVehicle";

type ActionProps = {
    setMutate: (mutate: any) => void;
    record: any;
};
const Action: React.FC<ActionProps> = ({ setMutate, record }) => {
    return (
        <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_FLEET}>
                <EditVehicle data={record} mutate={setMutate} />
            </PermissionCheck>
            <DeleteButton
                model="vehicle"
                title="vehicles"
                id={record.id}
                setMutate={setMutate}
                permission={DESTROY_FLEET}
            />
        </div>
    );
};

export default Action;
