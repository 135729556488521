import React from "react";
import PermissionCheck from "../../../components/authorizeComponent/PermissionCheck";
import DeleteButton from "../../../components/authorizeComponent/DeleteButton";
import { DESTROY_USER, UPDATE_USER } from "../../../utils/permission";
import EditUser from "./EditUser";

type ActionProps = {
    setMutate: (mutate: any) => void;
    record: any;
};
const Action: React.FC<ActionProps> = ({ setMutate, record }) => {
    return (
        <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_USER}>
                <EditUser data={record} mutate={setMutate} />
            </PermissionCheck>
            <DeleteButton
                model="users"
                title="user"
                id={record.id}
                setMutate={setMutate}
                permission={DESTROY_USER}
            />
        </div>
    );
};

export default Action;
