import { MapPinIcon } from "lucide-react";
import { Button } from "antd";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import DeleteButton from "../../components/authorizeComponent/DeleteButton";
import { DESTROY_SITE, UPDATE_SITE } from "../../utils/permission";
import EditSite from "./EditSite";

type ActionProps = {
    setMutate: (mutate: any) => void;
    setAsset: (asset: any) => void;
    setSplit: (asset: any) => void;
    record: any;
};

const Action: React.FC<ActionProps> = ({
    setMutate,
    setAsset,
    setSplit,
    record,
}) => {
    return (
        <div className="flex justify-content-between">
            <Button
                disabled={
                    !record.attributes ||
                    !record.attributes?.latitude ||
                    !record.attributes?.longitude
                }
                onClick={() => {
                    setSplit(true);
                    setAsset(record);
                }}
                size="small"
                className="size-8 rounded-full flex items-center justify-center"
            >
                <MapPinIcon size={16} />
            </Button>
            <PermissionCheck permission={UPDATE_SITE}>
                <EditSite data={record} mutate={setMutate} />
            </PermissionCheck>
            <DeleteButton
                model="site"
                title="site"
                id={record.id}
                setMutate={setMutate}
                permission={DESTROY_SITE}
            />
        </div>
    );
};

export default Action;
