import { Col, Form, Row, Select } from "antd";
import React, { useState } from 'react';
import LoadingButton from "../../../components/LoadingButton";
import _ from "lodash";
import TextField from "../../../components/formComponents/TextField";
import SelectTable from '../../../components/formComponents/SelectTable';
import { client2 } from "../../../operationalRequest";
import CustomSelectOptions from "../../../components/formComponents/CustomSelectOptions";
type EditUserComponentProps = {
  onSubmit: (values: any, form: any) => void;
  initialValue?: any; // The initial user data
  loading: boolean;
};

const EditUserComponent: React.FC<EditUserComponentProps> = ({ onSubmit, initialValue, loading }) => {
  const [form] = Form.useForm();
  const roles = [
    { value: 'support-superadmin', label: 'Support Superadmin' },
    { value: 'tenant', label: 'Tenant' },
    { value: "sub-tenant", label: 'Sub Tenant' },
    { value: "support-tenant", label: 'Support Tenant' },
  ];

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      initialValues={{
        ...initialValue,
        is_root_user: initialValue?.is_root_user === true,
      }}
      onFinish={(values: any) => {
        onSubmit(values, form);
      }}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <TextField label="Email" name="email" rules={[{ required: true, message: 'Please input email!' }]} />
        </Col>

        <Col span={24}>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: 'Please select a role!' }]}
          >
            <CustomSelectOptions options={roles} onChange={(val: any) => form.setFieldValue("role", val)} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="company_id"
            label="Company"
            rules={[{ required: true, message: 'Please select a company!' }]}
          >
            <SelectTable
              table='companies'
              placeholder='Select Company'
              onChange={(val: any) => {
                form.setFieldValue('company_id', val);
              }}
              value={form.getFieldValue('company_id')}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <TextField label="Username" name="username" rules={[{ required: true, message: 'Please input username!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="First Name" name="firstname" rules={[{ required: true, message: 'Please input first name!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Last Name" name="lastname" rules={[{ required: true, message: 'Please input last name!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Password" name="password" rules={[{ required: true, message: 'Please input password!' }]} />
        </Col>
      </Row>


      <Row>
        <Col span={24}>
          <Form.Item>
            <LoadingButton loading={loading} title={_.isEmpty(initialValue) ? "Save" : "Update"} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditUserComponent;
