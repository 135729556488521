import React from "react";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import DeleteButton from "../../components/authorizeComponent/DeleteButton";
import { DESTROY_BUILDING, UPDATE_BUILDING } from "../../utils/permission";
import EditAsset from "./EditAsset";

type ActionProps = {
    setMutate: (mutate: any) => void;
    record: any;
};
const Action: React.FC<ActionProps> = ({ setMutate, record }) => {
    return (
        <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_BUILDING}>
                <EditAsset data={record} mutate={setMutate} />
            </PermissionCheck>
            <DeleteButton
                model="building"
                title="building"
                id={record.id}
                setMutate={setMutate}
                permission={DESTROY_BUILDING}
            />
        </div>
    );
};

export default Action;
