import Action from "./Action";

const columns = (setMutate: (mutate: any) => void) => [
    {
        title: "First Name",
        dataIndex: "firstname",
        key: "firstname",
        sorter: true,
        filter: true,
        filterBy: {
            type: "text",
        },
    },
    {
        title: "Last Name",
        dataIndex: "lastname",
        key: "lastname",
        sorter: true,
        filter: true,
        filterBy: {
            type: "text",
        },
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: true,
        filter: true,
        filterBy: {
            type: "text",
        },
    },
    {
        title: "Last Login",
        dataIndex: "last_login",
        key: "last_login",
        sorter: true,
    },
    {
        title: "Action",
        dataIndex: "id",
        key: "action",
        render: (_: any, record: any) => {
            return <Action setMutate={setMutate} record={record} />;
        },
    },
];
export default columns;
