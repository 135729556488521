import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  CrossIcon,
  Delete,
  Info,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import { client } from "../../useRequest";
import toastr from "toastr";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import LeafMap from "../../components/LeafMap";
import { client2 } from "../../operationalRequest";
import _, { random } from "lodash";
import AddVehicle from "./AddVehicle";
import EditVehicle from "./EditVehicle";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CREATE_FLEET,
  UPDATE_FLEET,
  DESTROY_FLEET,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import OperationalGrid from '../../components/datatable/OperationalGrid'
import columns from './VehicleColumn'
const Vehicle = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const { user } = useSelector((state: any) => state.user);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/vehicle/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Vehicle Deleted Successfully");
    // }
  };

  //   const connectVehicle = async() => {
  //     const response = await client2.get('api/smartcar/authorize');
  //     console.log('response', response);
  //   }

  return (
    <Layout title="Fleets">
      <OperationalGrid
        columns={columns(setMutate)}
        url={'api/vehicle'}
        context="vehicles"
        addBody={
          <PermissionCheck permission={CREATE_FLEET}>
              <AddVehicle mutate={setMutate} />
          </PermissionCheck>
        }
        mutate={mutate}
        paginationOption={true}
      />
    </Layout>
  );
};

export default Vehicle;

type AssetAttributeProps = {
  asset: any;
  expanded: boolean;
  onClick: any;
};
const AssetAttribute: React.FC<AssetAttributeProps> = ({
  asset,
  expanded,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!asset?.attributes && expanded) {
      setLoading(false);
    }
  }, [asset, expanded]);

  if (!expanded) {
    return <></>;
  }
  return (
    <Modal
      okButtonProps={{ style: { display: "none" } }}
      width={"auto"}
      title="Vehicle"
      open={expanded}
      onCancel={onClick}
    >
      <Spin className="flex justify-start" spinning={loading}>
        <Card className="absolute z-[10000] m-5 ml-16 p-2 drop-shadow-md shadow-lg max-w-[300px]">
          {Object.entries(asset?.attributes || {}).map(
            ([key, value]: any, i) => {
              return (
                <p key={i}>
                  <span>
                    <strong>{key.toUpperCase()}</strong>: {value}{" "}
                  </span>
                </p>
              );
            }
          )}
        </Card>
        {!!asset?.attributes && (
          <LeafMap
            position={[asset.attributes.latitude, asset.attributes.longitude]}
          />
        )}
      </Spin>
    </Modal>
  );
};

type VehicleDetailsProps = {
  info: any;
  id: string;
};

const VehicleDetails: React.FC<VehicleDetailsProps> = ({ info, id }) => {
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([] as any);

  const fetchVehicleDetails = async () => {
    const response = await client2.get(`/api/smartcar/vehicle-details/${id}`);
    if (response.status === 200) {
      setData(response.data.data);
    }
  };

  useEffect(() => {
    if (!!toggle) {
      fetchVehicleDetails();
    }
  }, [toggle]);

  return (
    <>
      <Button
        disabled={!info}
        size="small"
        className="bg-blue-400 size-8 rounded-full py-3 flex items-center text-white"
        type="primary"
        onClick={() => setToggle(true)}
      >
        <Info />
      </Button>
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        title="Vehicle Info"
        open={toggle}
        onCancel={() => setToggle(false)}
      >
        {Object.entries(info || {}).map(([key, value]: any, i) => {
          return (
            <p key={i}>
              <span>
                <strong>{key.toUpperCase()}</strong>: {value}{" "}
              </span>
            </p>
          );
        })}

        {Object.entries(data || {}).map(([key, value]: any, i) => {
          return (
            <p key={i}>
              <span>
                <strong>{_.words(key).join(" ").toUpperCase()}</strong>: {value}{" "}
              </span>
            </p>
          );
        })}
      </Modal>
    </>
  );
};
