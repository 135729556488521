import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Divider, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import ChangePasswordComponent from "./ChangePasswordComponent";
import ChangeTimezoneComponent from "./ChangeTimezoneComponent";
import { client2 } from "../../operationalRequest";
import ChangeMonthlyCostComponent from "./ChangeMonthlyCostComponent";
import SetMonthlyCostComponent from "./SetMonthlyCostComponent";
import { getUserSuccess, setConfiguration } from "../../store/slice/userSlice";
import ChangeEmailComponent from "./ChangeEmailComponent";
import getUserProfile from "../../store/action/userAction";
import SelectNotificationChannel from "../notifications/SelectNotiticationChannel";

const Profile = () => {
    const dispatch = useDispatch();

    const { user, configuration } = useSelector((state: any) => state.user);
    const [loading, setLoading] = useState(false);

    const [initialNotificationPreferences, setInitialNotificationPreferences] =
        useState<Record<string, string[]>>({});

    const fetchData = async () => {
        try {
            const notificationChannelsResponse = await client2.get(
                "api/notification-preferences",
            );

            if (notificationChannelsResponse.status === 200) {
                const fetchedPreferences =
                    notificationChannelsResponse.data.data
                        .notification_preferences;
                setInitialNotificationPreferences(fetchedPreferences);
            }
        } catch (e) {
            console.error("Error fetching data:", e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePasswordChange = async (values: any, form: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const email = user?.email;
            if (values.password == values.confirmPassword) {
                const response = await client2.post(
                    `api/auth/change-password`,
                    {
                        email,
                        currentPassword: values.currentPassword,
                        password: values.password,
                        confirmPassword: values.confirmPassword,
                    },
                );
                if (response.status === 200) {
                    toastr.success("Password changed successfully!");
                    form.resetFields();
                }
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleEmailChange = async (values: any, form: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const email = values.email;
            if (values.password == values.confirmPassword) {
                const response = await client2.post(`api/auth/change-email`, {
                    email,
                });
                if (response.status === 200) {
                    dispatch<any>(getUserProfile());
                    toastr.success("Email changed successfully!");
                    // form.resetFields();
                }
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleTimezoneChange = async (values: any, form: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const email = user?.email;
            const response = await client2.patch(`api/users/${user.id}`, {
                details: {
                    timezone: values.timezone,
                },
                email,
                role: user.role,
                username: user.username,
            });
            //console.log("API Response: ", response);
            if (response.status === 200) {
                toastr.success("Timezone changed successfully!");
                form.resetFields();
                // Optionally update the user in state if needed
                // dispatch(updateUser({...user, timezone: values.timezone}));
            }
        } catch (e: any) {
            console.log(
                "Error occurred: ",
                e.response ? e.response.data : e.message,
            );
            toastr.error("Failed to change timezone.");
        } finally {
            setLoading(false);
        }
    };
    const handleCostSet = async (values: any, form: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const response = await client2.put(`api/configurations-update`, {
                key: "pv-cost-rate",
                value: values.value,
            });
            if (response.status === 200) {
                let conf = configuration.map((item: any) =>
                    item.key === "pv-cost-rate"
                        ? { ...item, value: values.value }
                        : item,
                );
                dispatch<any>(setConfiguration(conf));

                toastr.success("Cost set successfully!");
                // form.resetFields();
            }
        } catch (e: any) {
            console.log(
                "Error occurred: ",
                e.response ? e.response.data : e.message,
            );
            toastr.error("Failed to set monthly cost.");
        } finally {
            setLoading(false);
        }
    };

    const handleNotificationChannelSet = async (values: any, form: any) => {
        console.log(values, "this values");
        try {
            if (loading) return;
            setLoading(true);

            const response = await client2.post(
                `api/notification-preferences`,
                {
                    notification_preferences: values.notification_preferences,
                },
            );
            if (response.status === 200) {
                dispatch<any>(getUserProfile());
                toastr.success("Notification Channels set successfully!");
            }
        } catch (e: any) {
            console.log(
                "Error occurred: ",
                e.response ? e.response.data : e.message,
            );
            toastr.error("Failed to set notification channel");
        } finally {
            setLoading(false);
        }
    };

    const handleCostChange = async (values: any, form: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const getConfigResponse = await client2.get(
                `api/configurations/key/pv-cost-rate`,
            );
            const configId = getConfigResponse?.data?.data?.id;
            if (!configId) {
                toastr.error("Configuration not found!");
                return;
            }
            const response = await client2.put(
                `api/configurations/${configId}`,
                {
                    key: "pv-cost-rate",
                    value: values.key,
                    company_id: user?.company_id || 1,
                },
            );
            //console.log("API Response: ", response);
            if (response.status === 200) {
                toastr.success("Cost changed successfully!");
                form.resetFields();
            }
        } catch (e: any) {
            console.log(
                "Error occurred: ",
                e.response ? e.response.data : e.message,
            );
            toastr.error("Failed to change monthly cost.");
        } finally {
            setLoading(false);
        }
    };
    return (
        <Layout title={"Profile"}>
            <div className="px-8">
                <div>
                    <Avatar
                        size={{
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 100,
                        }}
                        icon={<UserOutlined />}
                    />
                    {user?.firstname && user?.lastname
                        ? `${user.firstname} ${user.lastname}`
                        : user?.firstname || user?.lastname || "Name not set"}
                    <p>{user?.email}</p>
                    <p>
                        Time zone:{" "}
                        {user?.details?.timezone || "No timezone set"}
                    </p>
                </div>
                <Divider orientation="left">Account Settings</Divider>
                <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <h4>Change Password</h4>
                        <ChangePasswordComponent
                            loading={loading}
                            onSubmit={handlePasswordChange}
                        />
                    </div>
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <h4>Change Timezone</h4>
                        <ChangeTimezoneComponent
                            loading={loading}
                            onSubmit={handleTimezoneChange}
                            initialValue={user}
                        />

                        <h4>Change Email</h4>
                        <ChangeEmailComponent
                            loading={loading}
                            onSubmit={handleEmailChange}
                            initialValue={user}
                        />
                    </div>
                </div>
                {["tenant", "support-tenant"].includes(user.role) && (
                    <>
                        <Divider orientation="left">Cost Rate</Divider>
                        <div
                            style={{
                                display: "flex",
                                gap: "16px",
                                flexWrap: "wrap",
                            }}
                        >
                            <div style={{ flex: 1, minWidth: "300px" }}>
                                <SetMonthlyCostComponent
                                    loading={loading}
                                    onSubmit={handleCostSet}
                                    initialValue={
                                        configuration?.find(
                                            (item: any) =>
                                                item.key === "pv-cost-rate",
                                        ) ?? { value: 1 }
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
                <Divider orientation="left">Notification</Divider>
                <div
                    style={{
                        display: "flex",
                        gap: "16px",
                        flexWrap: "wrap",
                    }}
                >
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <SelectNotificationChannel
                            loading={loading}
                            onSubmit={handleNotificationChannelSet}
                            initialValue={initialNotificationPreferences}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Profile;
