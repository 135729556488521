import { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ZoomControl from "../../components/ZoomControl";

interface PreviewModalProps {
    open: boolean;
    onClose: () => void;
    gridRef: React.RefObject<HTMLDivElement>;
    onConfirm?: () => void;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
    open,
    onClose,
    gridRef,
    onConfirm,
}) => {
    const [screenshot, setScreenshot] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [zoom, setZoom] = useState<number>(1);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });

    const MARGIN_MM = 10;

    const captureScreenshot = async () => {
        if (!gridRef.current) return;
        setLoading(true);

        try {
            const canvas = await html2canvas(gridRef.current, {
                logging: true,
                useCORS: true,
                allowTaint: true,
            });
            const imgData = canvas.toDataURL("image/png");
            setScreenshot(imgData);
            setLoading(false);
        } catch (error) {
            console.error("Error capturing screenshot:", error);
            setLoading(false);
        }
    };

    const generatePDF = () => {
        if (!screenshot) return;

        const doc = new jsPDF("p", "mm", "a4");
        const pageWidth = doc.internal.pageSize.getWidth();
        const img = new Image();
        img.src = screenshot;

        img.onload = () => {
            const imgWidth = pageWidth - 2 * MARGIN_MM;
            const imgHeight = (img.height * imgWidth) / img.width;
            doc.addImage(
                img.src,
                "PNG",
                MARGIN_MM,
                MARGIN_MM,
                imgWidth,
                imgHeight,
            );
            doc.save("dashboard.pdf");

            if (onConfirm) {
                onConfirm();
            }
            onClose();
        };

        img.onerror = (e) => {
            console.error("Image failed to load for PDF:", e);
            setLoading(false);
        };
    };

    useEffect(() => {
        if (open) {
            captureScreenshot();
            // Reset zoom and position when modal opens
            setZoom(1);
            setPosition({ x: 0, y: 0 });
        }
    }, [open]);

    // Reset zoom and position manually
    const resetView = () => {
        setZoom(1);
        setPosition({ x: 0, y: 0 });
    };

    // Zoom controls
    const handleZoomIn = () => {
        setZoom((prev) => Math.min(prev + 0.2, 2)); // Max zoom level: 2x
    };

    const handleZoomOut = () => {
        setZoom((prev) => Math.max(prev - 0.2, 1)); // Min zoom level: 1x
        setPosition({ x: 0, y: 0 }); // Reset position on zoom out
    };

    // Mouse Events for Dragging
    const handleMouseDown = (e: React.MouseEvent) => {
        if (zoom <= 1) return; // Only enable dragging when zoomed in
        setIsDragging(true);
        setStartPos({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        setPosition({
            x: e.clientX - startPos.x,
            y: e.clientY - startPos.y,
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <Modal
            title="Preview"
            width={500}
            open={open}
            onCancel={onClose}
            footer={[
                <div
                    key="footer"
                    className="flex flex-col justify-center gap-4"
                >
                    <div className="flex items-center gap-1">
                        <ZoomControl
                            zoom={zoom}
                            position={position}
                            handleZoomIn={handleZoomIn}
                            handleZoomOut={handleZoomOut}
                            resetView={resetView}
                        />
                    </div>
                    <div className="flex items-center justify-center gap-4">
                        <Button key="cancel" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            key="download"
                            type="primary"
                            onClick={generatePDF}
                        >
                            Confirm & Download
                        </Button>
                    </div>
                </div>,
            ]}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: "500px",
                    height: "500px",
                    border: "1px solid #ccc",
                    backgroundColor: "white",
                    padding: "10px",
                    overflow: "hidden",
                    cursor: zoom > 1 ? "grab" : "default",
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp} // Ensures drag stops if mouse leaves container
            >
                {loading ? (
                    <p>Loading preview...</p>
                ) : screenshot ? (
                    <img
                        src={screenshot}
                        alt="Preview"
                        style={{
                            transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                            transformOrigin: "center",
                            transition: isDragging
                                ? "none"
                                : "transform 0.2s ease-in-out",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                            cursor: zoom > 1 ? "grabbing" : "default",
                        }}
                        draggable={false} // Prevents default drag behavior
                    />
                ) : (
                    <p>No preview available.</p>
                )}
            </div>
        </Modal>
    );
};

export default PreviewModal;
