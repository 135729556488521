import Action from './Action';

const columns = (setMutate: (mutate: any) => void) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        filter: true,
        filterBy: {
            type: 'text'
        }

    },
    {
        title: "Storey",
        dataIndex: "storey_name",
        key: "storey_name",
        sorter: false,
        filter: false

    },
    {
        title: "Created By",
        dataIndex: "created_by",
        key: "email",
        sorter: false,
        filter: true,
        filterBy: {
            type: 'table',
            table: 'companyUser',
            filters: []
        }
    },
    {
        title: "Action",
        dataIndex: "id",
        key: "action",
        render: (_: any, record: any) => {
            return (
                <Action setMutate={setMutate} record={record} />
            );
        },
    },
];

export default columns;
