export const MANUFACTURE_TYPES = [
    { label: "Huawei", value: 1 },
    { label: "GoodWe", value: 2 },
    { label: "DEDDIE", value: 3 },
    { label: "ReMoni", value: 4 },
    { label: "Enphase", value: 5 },
    { label: "Fronius", value: 6 },
    { label: "Growatt", value: 7 },
    { label: "Hoymiles", value: 8 },
    { label: "SMA", value: 9 },
    { label: "Sofar", value: 10 },
    { label: "SolarEdge", value: 11 },
    { label: "Solax", value: 12 },
    { label: "Solis", value: 13 },
    { label: "Tesla", value: 14 },
    { label: "Custom", value: 30 },
];

export const DEVICE_TYPES = [
    { label: "Inverter", value: 10 },
    { label: "Grid Electrical Meter", value: 20 },
    { label: "Electrical Meter", value: 30 },
    { label: "Battery", value: 40 },
    { label: "Load", value: 50 },
    { label: "Sensor", value: 60 },
    { label: "Energy System", value: 70 },
    { label: "Unknown", value: -1 },
];

export const SYSTEM_TYPES = [
    { label: "Energy System", value: 2 },
    { label: "PV Energy System", value: 1 },
    { label: "Energy Storage", value: 3 },
    { label: "Energy Consumption", value: 4 },
    { label: "Grid Point", value: 5 },
    { label: "Unknown", value: -1 },
];

