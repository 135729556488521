import { ZoomIn, ZoomOut, RefreshCcw } from "lucide-react";

interface ZoomControlsProps {
    zoom: number;
    position: { x: number; y: number };
    handleZoomIn: () => void;
    handleZoomOut: () => void;
    resetView: () => void;
}

const ZoomControl: React.FC<ZoomControlsProps> = ({
    zoom,
    position,
    handleZoomIn,
    handleZoomOut,
    resetView,
}) => {
    return (
        <div className="flex items-center gap-1">
            <ZoomOut
                size={20}
                className={`transition-opacity ${
                    zoom > 1
                        ? "cursor-pointer opacity-100 active:scale-95"
                        : "cursor-not-allowed opacity-50 pointer-events-none"
                }`}
                onClick={() => zoom > 1 && handleZoomOut()}
            />
            <ZoomIn
                size={20}
                className={`transition-opacity ${
                    zoom < 3
                        ? "cursor-pointer opacity-100 active:scale-95"
                        : "cursor-not-allowed opacity-50 pointer-events-none"
                }`}
                onClick={() => zoom < 3 && handleZoomIn()}
            />
            <RefreshCcw
                size={20}
                className={`transition-opacity ${
                    zoom !== 1 || position.x !== 0 || position.y !== 0
                        ? "cursor-pointer opacity-100 active:scale-95"
                        : "cursor-not-allowed opacity-50 pointer-events-none"
                }`}
                onClick={() => {
                    if (zoom !== 1 || position.x !== 0 || position.y !== 0) {
                        resetView();
                    }
                }}
            />
        </div>
    );
};

export default ZoomControl;
