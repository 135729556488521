import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { client2 } from "../../operationalRequest";

interface GraphWidgetProps {
    chartType: "bar" | "line" | "pie";
    xAxisTitle?: string;
    yAxisTitle?: string;
    filter: GraphFilterProps;
    showToolbar?: boolean;
}

interface GraphFilterProps {
    dateType: string;
    date: string;
    systemId?: number;
    siteId?: number;
    deviceId?: number;
    kpi?: string;
}

const GraphWidget: React.FC<GraphWidgetProps> = (props) => {
    const [data, setData] = useState<any[]>([]);
    const [options, setOptions] = useState({
        chart: {
            borderRadius: 100,
            responsive: true,
        },
        noData: {
            text: "No data available",
            align: "center",
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: "14px",
            },
        },
        legend: {
            position: "top",
        },
        xaxis: {
            type: "category",
            title: {
                text: props.filter.dateType === "day" ? "Hour of Day" : "Date",
            },
            categories: [], // Dynamically update this
        },
        yaxis: {
            title: {
                text: props.yAxisTitle || "",
            },
            labels: {
                formatter: (value: any) => value.toFixed(2), // Format y-axis values
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: [2, 2, 2, 2],
        },
    });

    const fetchData = async () => {
        const filter = props.filter;
        // console.log('here', filter);
        const params = new URLSearchParams({
            dateType: filter.dateType,
            date: filter.date,
            ...(filter.systemId && { systemId: filter.systemId.toString() }),
            ...(filter.siteId && { siteId: filter.siteId.toString() }),
            ...(filter.deviceId && { deviceId: filter.deviceId.toString() }),
            ...(filter.kpi && { kpi: filter.kpi }),
        });

        try {
            const response = await client2.get(
                `api/widgets/charts?${params.toString()}`,
            );

            const formattedData = response.data.data.map((series: any) => ({
                ...series,
                data: series.data.map((point: any) => ({
                    x: point.x, // Keep x as a string
                    y: point.y, // Keep y as a number
                })),
            }));

            setData(formattedData);

            // Dynamically update x-axis categories
            const categories =
                formattedData.length > 0
                    ? formattedData[0].data.map((point: any) => point.x)
                    : [];

            setOptions((prevOptions) => ({
                ...prevOptions,
                chart: {
                    ...prevOptions.chart,
                    toolbar: {
                        show: props.showToolbar ?? false,
                    },
                },
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: categories,
                    title: {
                        text:
                            props.filter.dateType === "day"
                                ? "Hour of Day"
                                : "Date",
                    },
                },
            }));
        } catch (error) {
            console.error("Error fetching chart data:", error);
            setData([]); // Handle error case
        }
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                toolbar: {
                    show: props.showToolbar ?? false,
                },
            },
            yaxis: {
                ...prevOptions.yaxis,
                title: {
                    text: props.yAxisTitle || "",
                },
            },
            xaxis: {
                ...prevOptions.xaxis,
                title: {
                    text:
                        props.filter.dateType === "day"
                            ? "Hour of Day"
                            : "Date",
                },
            },
        }));

        fetchData();
    }, [props.filter, props.chartType, props.yAxisTitle, props.xAxisTitle]);

    return (
        <Chart
            key={props.chartType}
            type={props.chartType}
            options={options as any}
            series={data}
            width="100%"
            height="100%"
        />
    );
};

export default GraphWidget;
