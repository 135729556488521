import { Form, Checkbox, Table } from "antd";
import React, { useEffect, useState } from "react";
import LoadingButton from "../../components/LoadingButton";

interface SelectNotificationChannelProps {
    onSubmit: (
        values: { notification_preferences: Record<string, string[]> },
        form: any,
    ) => void;
    initialValue?: Record<string, string[]>;
    loading: boolean;
}

const notificationTypes = ["alert", "general"];
const notificationChannels = ["sms", "in_app", "mail"];

const SelectNotificationChannel: React.FC<SelectNotificationChannelProps> = ({
    onSubmit,
    initialValue,
    loading,
}) => {
    const [form] = Form.useForm();
    const [notificationPreferences, setNotificationPreferences] = useState<
        Record<string, string[]>
    >({});

    useEffect(() => {
        if (initialValue) {
            console.log(initialValue, "this sis initial again ");

            form.setFieldsValue({
                notification_preferences: initialValue,
            });
            setNotificationPreferences(initialValue);
        }
    }, [initialValue, form]);

    const handleCheckboxChange = (
        type: string,
        channel: string,
        checked: boolean,
    ) => {
        setNotificationPreferences((prev) => {
            const updated = {
                ...prev,
                [type]: prev[type] ? [...prev[type]] : [],
            };

            if (checked) {
                if (!updated[type].includes(channel)) {
                    updated[type].push(channel);
                }
            } else {
                updated[type] = updated[type].filter(
                    (item) => item !== channel,
                );
            }

            form.setFieldsValue({ notification_preferences: updated });
            return updated;
        });
    };

    return (
        <Form
            form={form}
            onFinish={() => {
                onSubmit(
                    { notification_preferences: notificationPreferences },
                    form,
                );
            }}
            layout="vertical"
        >
            <Table
                dataSource={notificationTypes.map((type) => ({
                    key: type,
                    type,
                }))}
                columns={[
                    {
                        title: "Notification Type",
                        dataIndex: "type",
                        key: "type",
                        render: (type) => <div>{type}</div>,
                    },
                    ...notificationChannels.map((channel) => ({
                        title: channel,
                        dataIndex: channel,
                        key: channel,
                        render: (_: any, record: any) => (
                            <Checkbox
                                checked={
                                    notificationPreferences[
                                        record.type
                                    ]?.includes(channel) || false
                                }
                                onChange={(e) =>
                                    handleCheckboxChange(
                                        record.type,
                                        channel,
                                        e.target.checked,
                                    )
                                }
                            />
                        ),
                    })),
                ]}
                pagination={false}
            />

            <Form.Item style={{ marginTop: 16 }}>
                <LoadingButton
                    loading={loading}
                    title={"Set Notification Channels"}
                />
            </Form.Item>
        </Form>
    );
};

export default SelectNotificationChannel;
