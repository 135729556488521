import React from "react";
import { Form, Input } from "antd";
import LoadingButton from "../../components/LoadingButton";
import _ from "lodash";
import TextArea from "antd/es/input/TextArea";
import SelectTable from "../../components/formComponents/SelectTable";
import { useSelector } from "react-redux";

type EditSiteComponentProps = {
    onSubmit: any;
    initialValue?: any;
    loading: boolean;
};

const EditSiteComponent: React.FC<EditSiteComponentProps> = ({
    onSubmit,
    initialValue,
    loading,
}) => {
    const [form] = Form.useForm();
    const { role } = useSelector((state: any) => state.role);

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={initialValue}
            onFinish={(values: any) => onSubmit(values, form)}
            autoComplete="off"
            layout="vertical"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input name!" }]}
            >
                <Input />
            </Form.Item>

            {!role.role.includes("sub-tenant") && (
                <Form.Item
                    label="Assign To"
                    name="assign_to"
                    rules={[
                        {
                            required: false,
                            message: "Please select the assignee!",
                        },
                    ]}
                >
                    <SelectTable
                        placeholder="Select User"
                        onChange={(val: any) =>
                            form.setFieldValue("assign_to", val)
                        }
                        value={form.getFieldValue("assign_to")}
                        table="tenantUser"
                    />
                </Form.Item>
            )}

            <Form.Item
                label="Description"
                name={"description"}
                rules={[
                    { required: false, message: "Please input Description!" },
                ]}
            >
                <TextArea rows={4} placeholder="Enter Description" />
            </Form.Item>

            <Form.Item>
                <LoadingButton
                    loading={loading}
                    title={_.isEmpty(initialValue) ? "Save" : "Update"}
                />
            </Form.Item>
        </Form>
    );
};

export default EditSiteComponent;
