import { useEffect, useState } from "react";
import { SaveAllIcon, Settings2Icon, X } from "lucide-react";
import { Button } from "antd";
import toastr from "toastr";

import CardWidget from "./CardWidget";
import { client2 } from "../../operationalRequest";
import CustomModal from "../CustomModal";
import EditCardWidgetComponent from "./forms/CardEditForm";
import EditGraphWidgetComponent from "./forms/GraphEditForm";
import GraphWidgetWrapper from "./GraphWidgetWrapper";
const { Responsive, WidthProvider } = require("react-grid-layout");

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {
    open: boolean;
}

const GridLayout = ({ open }: GridLayoutProps) => {
    const [layouts, setLayouts] = useState({
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
    });

    const [layoutValues, setLayoutValue] = useState<
        { id: string; values: any }[]
    >([]);

    const [loading, setLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const defaultProps = {
        className: "layout",
        rowHeight: 60,
        cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
        breakpoints: { xs: 480, xxs: 0 },
    };

    const handleRemoveLayout = (id: string) => {
        setLayouts((prevLayouts: any) => {
            const updatedLayouts = Object.keys(prevLayouts).reduce(
                (acc: any, breakpoint) => {
                    acc[breakpoint] = prevLayouts[breakpoint].filter(
                        (item: any) => item.i !== id,
                    );
                    return acc;
                },
                {},
            );
            return updatedLayouts;
        });

        setLayoutValue((prevLayoutValues) =>
            prevLayoutValues.filter((item) => item.id !== id),
        );
    };

    const handleModalOnSubmit = (values: any, id: string) => {
        setLayoutValue((prevLayoutValues) => {
            const existingIndex = prevLayoutValues.findIndex(
                (item) => item.id === id,
            );

            if (existingIndex !== -1) {
                return prevLayoutValues.map((item, index) =>
                    index === existingIndex ? { ...item, values } : item,
                );
            } else {
                return [...prevLayoutValues, { id, values }];
            }
        });

        toastr.success("Layout updated");
    };

    const generateDOM = () => {
        return layouts.lg.map((item: any, i: any) => {
            const widgetData = layoutValues.find(
                (value) => value.id === item.i,
            );

            return (
                <div
                    key={item.i}
                    className="flex flex-col p-4 bg-gray-50 rounded w-full h-full overflow-hidden"
                >
                    {open && (
                        <div className="flex justify-end close-btn">
                            <CustomModal title="Edit" icon={<Settings2Icon />}>
                                {item.i.startsWith("card") ? (
                                    <EditCardWidgetComponent
                                        loading={loading}
                                        onSubmit={(values: any) =>
                                            handleModalOnSubmit(values, item.i)
                                        }
                                        initialValue={widgetData?.values || {}}
                                    />
                                ) : (
                                    <EditGraphWidgetComponent
                                        loading={loading}
                                        onSubmit={(values: any) =>
                                            handleModalOnSubmit(values, item.i)
                                        }
                                        initialValue={widgetData?.values || {}}
                                    />
                                )}
                            </CustomModal>

                            <X
                                className="cursor-pointer hover:text-red-500"
                                onClick={() => handleRemoveLayout(item.i)}
                            />
                        </div>
                    )}
                    <div className="flex-grow">
                        {item.i.startsWith("card-widget-")
                            ? (() => {
                                  return (
                                      <CardWidget
                                          title={
                                              widgetData?.values?.title ||
                                              "No Title"
                                          }
                                          filter={{
                                              kpi: widgetData?.values?.kpi,
                                              timePeriod:
                                                  widgetData?.values
                                                      ?.timePeriod,
                                              systemId:
                                                  widgetData?.values?.systemId,
                                              siteId: widgetData?.values
                                                  ?.siteId,
                                              deviceId:
                                                  widgetData?.values?.deviceId,
                                          }}
                                          description={
                                              widgetData?.values?.description ||
                                              ""
                                          }
                                      />
                                  );
                              })()
                            : (() => {
                                  return (
                                      <GraphWidgetWrapper
                                          dateType={
                                              widgetData?.values?.dateType
                                          }
                                          date={widgetData?.values?.date}
                                          dateFilter={
                                              widgetData?.values?.dateFilter
                                          }
                                          filter={{
                                              chartType:
                                                  widgetData?.values
                                                      ?.chartType || "bar",
                                              xAxisTitle:
                                                  widgetData?.values
                                                      ?.xAxisTitle ||
                                                  "No Title",
                                              yAxisTitle:
                                                  widgetData?.values
                                                      ?.yAxisTitle ||
                                                  "No Title",
                                              showToolbar:
                                                  widgetData?.values
                                                      ?.showToolbar,
                                              systemId:
                                                  widgetData?.values?.systemId,
                                              siteId: widgetData?.values
                                                  ?.siteId,
                                              deviceId:
                                                  widgetData?.values?.deviceId,
                                              kpi: widgetData?.values?.kpi,
                                          }}
                                      />
                                  );
                              })()}
                    </div>
                </div>
            );
        });
    };

    const handleLayoutChange = (layout: any, allLayouts: any) => {
        setLayouts((prevLayouts: any) => {
            const filteredLayouts = {
                ...allLayouts,
                lg: allLayouts.lg.filter(
                    (item: any) => item.i !== "__dropping-elem__",
                ),
            };

            if (
                JSON.stringify(prevLayouts) !== JSON.stringify(filteredLayouts)
            ) {
                return filteredLayouts;
            }

            return prevLayouts;
        });
    };

    const handleDrop = (
        layout: any,
        layoutItem: any,
        event: React.DragEvent,
    ) => {
        const widgetValue = event.dataTransfer.getData("widget-type");
        const newItem = {
            i: `${widgetValue + "-" + Date.now()}`,
            x: layoutItem.x,
            y: layoutItem.y,
            w: 4,
            h: 3,
            widgetType: widgetValue,
        };
        // console.log('fasdfa', newItem)
        setLayouts((prevLayouts: any) => {
            const updatedLayouts = { ...prevLayouts };

            // Update all breakpoints with the new item
            Object.keys(updatedLayouts).forEach((breakpoint) => {
                updatedLayouts[breakpoint] = [
                    ...(updatedLayouts[breakpoint] || []),
                    { ...newItem }, // Add the new item
                ];
            });

            return updatedLayouts;
        });
    };

    const fetchLayout = async () => {
        try {
            const response = await client2.get("api/dynamic-dashboards");
            if (response.data) {
                if (response.data.data && response.data.data.config) {
                    setLayouts(
                        response.data.data.config.layouts || {
                            lg: [],
                            md: [],
                            sm: [],
                            xs: [],
                            xxs: [],
                        },
                    );
                    setLayoutValue(response.data.data.config.props || {});
                }
            }
            setIsLoaded(true);
        } catch (error) {
            console.error("Error fetching layouts:", error);
        }
    };

    useEffect(() => {
        fetchLayout();
    }, []);

    useEffect(() => {
        if (open) {
            // This triggers the layout to recalculate when the drawer opens
            setLayouts((prevLayouts) => ({
                ...prevLayouts, // Preserve existing layout
            }));
        } else {
            // Handle layout adjustment when the drawer closes
            setLayouts((prevLayouts) => ({
                ...prevLayouts, // Preserve existing layout
            }));
        }
    }, [open]);

    const saveLayout = async () => {
        try {
            await client2.post("api/dynamic-dashboards", {
                config: {
                    layouts,
                    props: layoutValues,
                },
            });
            toastr.success("Layouts saved successfully");
        } catch (error) {
            console.error("Error saving layouts:", error);
            toastr.error("Error saving layouts");
        }
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className={`rounded-lg ${open ? "bg-gray-300" : ""} mb-10`}>
            {open && (
                <div className="flex justify-end p-2">
                    <Button type="default" onClick={() => saveLayout()}>
                        <SaveAllIcon className="w-full h-full" />
                    </Button>
                </div>
            )}
            <ResponsiveGridLayout
                {...defaultProps}
                layouts={layouts}
                onLayoutChange={handleLayoutChange}
                onDrop={handleDrop}
                isDroppable={true}
                draggableCancel=".close-btn"
                autoSize={true}
                style={{ minHeight: "150px" }}
                isDraggable={open}
                isResizable={open}
                useCSSTransforms={false}
            >
                {generateDOM()}
            </ResponsiveGridLayout>
        </div>
    );
};

export default GridLayout;
