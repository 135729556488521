import { Button, Drawer, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import EditAssetComponent from "./EditAssetComponent";
import EditAttributeComponent from "./EditAttributeComponent";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import EditButton from "../../components/button/EditButton";
type EditAssetProps = {
    data: any;
    mutate?: any;
};
const EditAsset: React.FC<EditAssetProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const editAsset = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            const response = await client2.put(`api/building/${data?.id}`, {
                building: values,
            });
            if (response.status === 200) {
                mutate({ data: "data" });
                setOpen(false);
                toastr.success("Asset Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };
    const editAttribute = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            const response = await client2.put(`api/building/${data?.id}`, {
                building: {
                    name: values.name || data.name,
                    description: values.description || data.description,
                    site_id: values.site_id || data.site_id,
                    label: values.label || data.label,
                    assign_to: values.assign_to ?? data.assign_to,
                    attributes: {
                        ...data.attributes,
                        ...values.attributes,
                    },
                },
            });
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false);
                toastr.success("Building Attribute Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };
    return (
        <>
            <EditButton onClick={showDrawer} title="Edit Building" />
            <Drawer
                size="large"
                title="Edit Building"
                onClose={onClose}
                open={open}
            >
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "Building",
                            key: "1",
                            children: (
                                <EditAssetComponent
                                    loading={loading}
                                    initialValue={{
                                        ...data,
                                        building_id: !!data.building_id
                                            ? `${data.building_id}|${data.building_string_type}`
                                            : null,
                                    }}
                                    onSubmit={editAsset}
                                />
                            ),
                        },
                        {
                            label: "Attributes",
                            key: "2",
                            children: (
                                <EditAttributeComponent
                                    loading={loading}
                                    initialValue={{
                                        ...data,
                                        building_id: !!data.building_id
                                            ? `${data.building_id}|${data.building_string_type}`
                                            : null,
                                        attributes: data.attributes,
                                    }}
                                    onSubmit={editAttribute}
                                />
                            ),
                        },
                    ]}
                />
            </Drawer>
        </>
    );
};

export default EditAsset;
