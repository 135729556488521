import Action from './Action';

const columns = (setMutate: (mutate: any) => void) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "VIN",
        dataIndex: "vin",
        key: "vin",
        sorter: true,
      },
      {
        title: "Label",
        dataIndex: "label",
        key: "label",
        sorter: true,
      },
      {
        title: "Owner Name",
        dataIndex: "owner_name",
        key: "owner_name",
        render: (row: any, record: any) => {
          return <>{record.attributes?.owner_name}</>;
        },
      },
      {
        title: "Driver Name",
        dataIndex: "driver_name",
        key: "driver_name",
        render: (row: any, record: any) => {
          return <>{record.attributes?.driver_name}</>;
        },
      },
      {
        title: "Licence Plate",
        dataIndex: "licence",
        key: "licence",
        render: (row: any, record: any) => {
          return <>{record.attributes?.licence}</>;
        },
      },
      {
        title: "Created By",
        dataIndex: "created_by.email",
        key: "email",
        sorter: false,
        render: (row: any, record: any) => {
          return record.created_by?.email;
        },
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "action",
        render: (_: any, record: any) => {
            return (
                <Action setMutate={setMutate} record={record} />
            );
        },
    },
];

export default columns;
