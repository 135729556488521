import React from "react";
import DeleteButton from "../../components/authorizeComponent/DeleteButton";
import { DESTROY_SYSTEM } from "../../utils/permission";
import NotificationDetails from "./NotificationDetails";
// import EditSystem from "./EditSystem";

type ActionProps = {
    setMutate: (mutate: any) => void;
    record: any;
};
const Action: React.FC<ActionProps> = ({ setMutate, record }) => {
    return (
        <div className="flex justify-content-between gap-2">
            <NotificationDetails id={record.id} setMutate={setMutate} />
            <DeleteButton
                model="notifications"
                title="notification"
                id={record.id}
                setMutate={setMutate}
                permission={DESTROY_SYSTEM}
            />
        </div>
    );
};

export default Action;
