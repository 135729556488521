import { Button, Drawer, Tabs } from "antd";
import { ArrowBigRightDash } from "lucide-react";
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import { client2 } from "../../../operationalRequest";
import EditUserComponent from "./EditUserComponent";
import EditPermissionComponents from "./EditPermissionComponent";
import EditButton from "../../../components/button/EditButton";
type EditUserProps = {
    data: any;
    mutate?: any;
};

const EditUser: React.FC<EditUserProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editUser = async (values: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const response = await client2.put(`api/users/${data?.id}`, {
                user: values,
            });
            if (response.status === 200) {
                mutate({ data: "data" });
                setOpen(false);
                toastr.success("User Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false); // Ensure loading is set to false in the finally block
        }
    };

    const editPermission = async (values: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const response = await client2.put(
                `api/user/permission/${data?.id}`,
                {
                    ...values,
                },
            );
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false);
                toastr.success("Permissions Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const getPermissions = async () => {
        try {
            const response = await client2.get(
                `api/user/permission/${data?.id}`,
            );
            // console.log(response.data); // Log permissions to the console
        } catch (e: any) {
            console.log(e);
        }
    };

    const handleTabChange = (key: string) => {
        if (key === "2") {
            getPermissions(); // Fetch permissions when the Permissions tab is clicked
        }
    };
    return (
        <>
            <EditButton onClick={showDrawer} title="Edit User" />
            <Drawer
                size="large"
                title="Edit User"
                onClose={onClose}
                open={open}
            >
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "User",
                            key: "1",
                            children: (
                                <EditUserComponent
                                    loading={loading}
                                    initialValue={{
                                        ...data,
                                        user_id: !!data.user_id
                                            ? `${data.user_id}|${data.user_string_type}`
                                            : null,
                                    }}
                                    onSubmit={editUser}
                                />
                            ),
                        },
                        {
                            label: "Permissions",
                            key: "2",
                            children: (
                                <EditPermissionComponents
                                    loading={loading}
                                    initialValue={{
                                        permissions: data.permissions,
                                        role: data.role,
                                    }}
                                    onSubmit={editPermission}
                                />
                            ),
                        },
                    ]}
                />
            </Drawer>
        </>
    );
};
export default EditUser;
