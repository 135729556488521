import React, { useState } from "react";
import { Drawer, Tabs } from "antd";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import EditButton from "../../components/button/EditButton";
import ImportDevices from "./ImportDevices";

type EditIntegrationProps = {
  data: any;
  mutate?: any;
};
const EditIntegration: React.FC<EditIntegrationProps> = ({ data, mutate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const editIntegration = async (values: any) => {
    try {
      if (loading) return;
      setLoading((curr: any) => true);
      const response = await client2.put(`api/systems/${data?.id}`, {
        system: values,
      });
      if (response.status === 200) {
        mutate({ data: "data" });
        setOpen(false);
        toastr.success("System Updated Successfully!");
      }
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  return (
    <>
      <EditButton onClick={showDrawer} title="Import Devices"/>
      <Drawer size="large" title="Import Devices" onClose={onClose} open={open}>
      <ImportDevices
                  loading={loading}
                  integrationId={data.id}
                  setLoading={setLoading}
                />
      </Drawer>
    </>
  );
};

export default EditIntegration;
