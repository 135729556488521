import Action from "./Action";

const columns = (
    setMutate: (mutate: any) => void,
    setAsset: (asset: any) => void,
    setSplit: (asset: any) => void,
) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        filter: true,
        filterBy: {
            type: "text",
        },
    },
    {
        title: "Created By",
        dataIndex: "created_by",
        key: "email",
        sorter: false,
        filter: true,
        filterBy: {
            type: "table",
            table: "companyUser",
            filters: [],
        },
    },
    {
        title: "Country",
        dataIndex: "country",
        key: "country",
        render: (row: any, record: any) => {
            return <>{record.attributes?.country}</>;
        },
    },
    {
        title: "Street Name",
        dataIndex: "street_name",
        key: "street_name",
        render: (row: any, record: any) => {
            return <>{record.attributes?.street_name}</>;
        },
    },
    {
        title: "City",
        dataIndex: "city",
        key: "city",
        render: (row: any, record: any) => {
            return <>{record.attributes?.city}</>;
        },
    },
    {
        title: "Facility Manager",
        dataIndex: "ownerName",
        key: "ownerName",
        render: (row: any) => {
            <div></div>;
        },
    },
    {
        title: "Action",
        dataIndex: "id",
        key: "action",
        render: (_: any, record: any) => {
            return (
                <Action
                    setMutate={setMutate}
                    setAsset={setAsset}
                    setSplit={setSplit}
                    record={record}
                />
            );
        },
    },
];

export default columns;
