import React, { useState } from "react";
import { Modal } from "antd";
import { client2 } from "../../operationalRequest";
import toastr from "toastr";
import EditSiteComponent from "./EditSiteComponent";
import AddButton from "../../components/button/AddButton";

type AddSiteProps = {
    mutate?: any;
};

const AddSite: React.FC<AddSiteProps> = ({ mutate }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const onSubmit = async (values: any, form: any) => {
        try {
            if (loading) return;
            const response = await client2.post("api/site", {
                site: values,
            });
            mutate({ data: response.data });
            setOpen(false);
            toastr.success("Site Created Successfully");
            form.resetFields();
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };

    return (
        <>
            <AddButton title="Add Site" setOpen={setOpen} />
            <Modal
                title={"Add Site"}
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={800}
                okButtonProps={{ style: { display: "none" } }}
            >
                <EditSiteComponent loading={loading} onSubmit={onSubmit} />
            </Modal>
        </>
    );
};

export default AddSite;
