import React, { useState, useEffect } from "react";
import GraphWidget from "./GraphWidget";
import CustomDatePicker from "../CustomDatePicker";
import dayjs from "dayjs";
import NEOChart from "../../page/dashboard/NEOChart";
import NormalizedPerformanceChart from "../../page/dashboard/NormalizedPerformanceChart";

interface GraphWidgetWrapperProps {
    dateType: string;
    date: string;
    dateFilter: any;
    filter: {
        chartType: "bar" | "line" | "pie";
        xAxisTitle?: string;
        yAxisTitle?: string;
        showToolbar?: boolean;
        systemId?: number;
        siteId?: number;
        deviceId?: number;
        kpi?: string;
    };
}
const GraphWidgetWrapper: React.FC<GraphWidgetWrapperProps> = ({
    dateFilter,
    filter,
}) => {
    const getDateRange = (dateType: string) => {
        switch (dateType) {
            case "day":
                return dayjs().subtract(1, "day").format("YYYY-MM-DD");
            case "week":
                return `${dayjs().startOf("week").format("YYYY-MM-DD")} to ${dayjs()
                    .endOf("week")
                    .format("YYYY-MM-DD")}`;
            case "month":
                return dayjs().startOf("month").format("YYYY-MM");
            case "year":
                return dayjs().startOf("year").format("YYYY");
            default:
                return "";
        }
    };
    const [selectedDate, setSelectedDate] = useState<string | string[]>(
        getDateRange(dateFilter?.[0] ?? ""),
    );
    const [selectedDateType, setSelectedDateType] = useState<string>(
        dateFilter?.[0] ?? "",
    );

    const handleDateChange = (dateType: string, date: string | string[]) => {
        setSelectedDate(date);
        setSelectedDateType(dateType);
    };

    useEffect(() => {}, [selectedDate, selectedDateType, filter]);

    return (
        <div className="flex flex-col h-full w-full">
            <CustomDatePicker
                onDateChange={handleDateChange}
                dateFilter={dateFilter}
            />
            <div className="flex-grow relative">
                {filter.kpi == "NEO" ? (
                    <NEOChart
                        filter={{
                            dateType: selectedDateType,
                            date: selectedDate.toString(),
                            siteId: filter.siteId,
                        }}
                    />
                ) : filter.kpi === "NP" ? (
                    <NormalizedPerformanceChart
                        filter={{
                            dateType: selectedDateType,
                            date: selectedDate.toString(),
                            siteId: filter.siteId,
                        }}
                    />
                ) : (
                    <GraphWidget
                        chartType={filter.chartType}
                        xAxisTitle={filter.xAxisTitle}
                        yAxisTitle={filter.yAxisTitle}
                        showToolbar={filter.showToolbar}
                        filter={{
                            dateType: selectedDateType,
                            date: selectedDate.toString(),
                            systemId: filter.systemId,
                            siteId: filter.siteId,
                            deviceId: filter.deviceId,
                            kpi: filter.kpi,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default GraphWidgetWrapper;
