import {
    NOTIFICATION_TYPES,
    NOTIFICATION_STATUSES,
    NOTIFICATION_PRIORITIES,
} from "../../constants/notificationTypes";
import Action from "./Action";

const columns = (setMutate: (mutate: any) => void) => [
    {
        title: "Title",
        dataIndex: "data",
        key: "message",
        render: (_: any, record: any) => {
            const { title } = record.data || {};
            if (title) {
                return `${title}`;
            } else {
                return "-";
            }
        },
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: true,
        filter: true,
        filterBy: {
            type: "options",
            options: NOTIFICATION_TYPES.map((type) => ({
                value: type.value,
                label: type.label,
            })),
        },
    },
    {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        sorter: true,
        render: (_: any, record: any) => {
            const notificationPriority = NOTIFICATION_PRIORITIES.find(
                (priority) => priority.value === record.priority,
            );
            return notificationPriority
                ? notificationPriority.label
                : "Unknown";
        },
        filter: true,
        filterBy: {
            type: "options",
            options: NOTIFICATION_PRIORITIES.map((priority) => ({
                value: priority.value,
                label: priority.label,
            })),
        },
    },
    {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
        render: (createdAt: string) => new Date(createdAt).toLocaleString(),
        filter: true,
        filterBy: {
            type: "date",
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: true,
        render: (_: any, record: any) => {
            const notificationStatus = NOTIFICATION_STATUSES.find(
                (status) => status.value === record.status,
            );
            return notificationStatus ? notificationStatus.label : "Unknown";
        },
        filter: true,
        filterBy: {
            type: "options",
            options: NOTIFICATION_STATUSES.map((status) => ({
                value: status.value,
                label: status.label,
            })),
        },
    },
    {
        title: "Action",
        dataIndex: "id",
        key: "action",
        render: (_: any, record: any) => {
            return <Action setMutate={setMutate} record={record} />;
        },
    },
];

export default columns;
